import React, { useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';
// const data = [
//   {
//     name: '2022/05/05',
    
//     calls: 24,
//     amt: 2400,
//   },
//   {
//     name: '2022/05/06',
    
//     calls: 14,
//     amt: 2400,
//   },
//   {
//     name: '2022/05/07',
    
//     calls: 34,
//     amt: 2400,
//   }
// ];

export default function Example(props) {

  
  
  

 {
    return (
        <>
      
     

     
        <LineChart
        
          width={props.width}
          height={props.height}
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="number_of_calls" stroke="#8884d8" activeDot={{ r: 8 }} />
         
        </LineChart>
       
      </>
    );
  }
}
