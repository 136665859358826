import * as React from "react";
import { useState } from "react";
import TableRowe from "./TableRowe";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SingleLineChart from "./SingleLineChart";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import axios from "axios";
import jsPDF from "jspdf";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Pdf from "react-to-pdf";
import {subDays, addDays} from "date-fns"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflowY: "scroll",
  p: 4,
};
export const data = [
  ["Year", "Sales", "Expenses"],
  ["2004", 1000, 400],
  ["2005", 1170, 460],
  ["2006", 660, 1120],
  ["2007", 1030, 540],
];

export const options = {
  title: "Company Performance",
  curveType: "function",
  legend: { position: "bottom" },
};

function LocationCard(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        color: "white",
        p: 1,
        m: 1,
        borderRadius: 1,
        textAlign: "center",
        fontSize: "1rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}
const ref = React.createRef();
const ModalReport = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [data, setData] = useState();
  const [showDiv, setShowDiv] = useState(false);
  const [district, setDistrict] = React.useState("");
  const [location, setLocation] = React.useState("");
  
  const printRef = React.useRef();
 
  const handleChangedistrict = (event) => {
    setDistrict(event.target.value);
  };

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  var res = startDate.toISOString().substring(0, 10);
  console.log(res);
  var stringii = res.slice(8) + "-" + res.slice(5, 8) + res.slice(0, 4);
  var rese = endDate.toISOString().substring(0, 10);
  var fringii = rese.slice(8) + "-" + rese.slice(5, 8) + rese.slice(0, 4);
  

  var BASE_URL = `https://cors-everywhere.herokuapp.com/http://3.110.221.53:2201/api/v1/call/logs?startOffset=${res}&endOffset=${rese}`;
  
  function getDataForReport() {
    axios.get(BASE_URL).then((response) => {
      console.log(response.data);
      setData(response.data.data);
      setShowDiv(true);
    });
  }

  
  function createTablerow(singleData, index) {
    var date1 = new Date(singleData.ts.slice(0,19))
var ws = date1.toString()
var ds = ws.slice(0,24)
    return (
      <TableRowe
       in = {index + 1}
        imei={singleData.access_token}
        call_time={singleData.call_time}
        call_mode = {singleData.call_mode}
        timeOfCall = {ds}
      />
    );
  }
  
  // 2022-12-01
  //2022-01-27

  return (
    <div >
    
    
     <div className="row mobilefooter mb-4">
         <div className="col-lg-5">

         </div>

         <div className="col-lg-6 mt-4">
         <Button
        sx={{
          
          textTransform: "capitalize",
          
        }}
        variant="contained"
        onClick={handleOpen}
      >
        Generate Report
      </Button>
         </div>
     
     </div>
      

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ margin: "auto", width: "0px" }}
      >
        <Box sx={style}>
          <Typography
            sx={{ fontWeight: "600" }}
            id="modal-modal-title"
            variant="h4"
            component="h2"
            className="text mb-4"
          >
            Report :-
          </Typography>
          <Box sx={{ flexDirection: "row" }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl sx={{ m: 1, minWidth: 80 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Bus Stop
                  </InputLabel>
                  <Select
                  sx= {{width: "180px"}}
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={district}
                    onChange={handleChangedistrict}
                    autoWidth={true}
                    label="District"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Lodhi Colony</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={3}>
                <FormControl sx={{ m: 1, minWidth: 80 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Location
                  </InputLabel>
                  <Select
                   sx= {{width: "180px"}}
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={location}
                    onChange={handleChangeLocation}
                    autoWidth ={true}
                    label="Location"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Lodhi Colony</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              {/* <Grid item xs={ 3 }>
                                <LocationCard>Nehru Place</LocationCard>
                            </Grid>
                            <Grid item xs={ 3 }>
                                <LocationCard>Dwarka</LocationCard>
                            </Grid> */}
            </Grid>

            {/* Second Row */}
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={4}>
                <label for="birthday">From:</label>
                <DatePicker
                  selected={startDate}
                  excludeDateIntervals={[{start: subDays(new Date("2021/02/08"),0), end: addDays(new Date("2022/01/15"),0) }]}
                  onChange={(date) => setStartDate(date)}
                />
              </Grid>
              <Grid item xs={4}>
                <label for="birthday">To: </label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                 
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  className="mt-3"
                  onClick={getDataForReport}
                  variant="contained"
                >
                  Get
                </Button>

                <Pdf targetRef={ref} filename="panic-report.pdf">
        {({ toPdf }) => <Button variant="contained" className="mx-4 mt-3" onClick={toPdf}>Get Pdf</Button>}
      </Pdf>
                
              </Grid>
            </Grid>

            {/* Table */}
            <div ref={ref}>
            <Grid className="mt-4 mb-4" conatainer spacing={2}>
            <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn-primary"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
            <table id="table-to-xls" class="download-table-xls-button table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Location</th>
      
      <th scope="col">IMEI</th>
      <th scope="col">Time of Call</th>
      <th scope="col">Call Duration</th>
      <th scope="col">Call Mode</th>
    </tr>
  </thead>
  <tbody>
    
    {showDiv ? data.map(createTablerow) : null}
  </tbody>
</table>

            </Grid>

            {/* Graph */}
            <Grid container={2}>

                {showDiv ? <SingleLineChart /> :null}
              
            </Grid>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalReport;
