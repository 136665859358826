import React, {useState} from 'react'

function CreateTabs() {

    const [activeTab, setActiveTab] = useState(0);
  
    const handleTabClick = (index) => {
      setActiveTab(index);
    }


  return (
    <div>
<div style={{"marginTop":"400px"}} className="tabs">
        <button className={activeTab === 0 ? 'active' : ''} onClick={() => handleTabClick(0)}>Tab 1</button>
        <button className={activeTab === 1 ? 'active' : ''} onClick={() => handleTabClick(1)}>Tab 2</button>
        <button className={activeTab === 2 ? 'active' : ''} onClick={() => handleTabClick(2)}>Tab 3</button>
      </div>
      <div className="tab-content">
        {activeTab === 0 && <p>Tab 1 content</p>}
        {activeTab === 1 && <p>Tab 2 content</p>}
        {activeTab === 2 && <p>Tab 3 content</p>}
      </div>
    </div>
  )
}

export default CreateTabs