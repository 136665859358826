import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";

import axios from "axios";
import {BrowserRouter as Router,Link,Route} from 'react-router-dom'
import IndividualDevice from "../Device/IndividualDevice";
import AppContext from "../../../context/app-context";

const url = process.env.REACT_APP_URL


function Map(props) {
    const { siteCode } = useParams();
    const [lata, setLat]= useState(0)
    const [lona, setLon] = useState(0)
    const [coordinates,setCoordinates ]= useState()
    const [iconColor, setIconColor] = useState()
    
    // useEffect(() => {
    //     async function getData(){
    //         await axios

    //         .get(`${url}/api/v1/get/master`)

    //         .then((response) => {
    //           const j = response.data.data.filter((v) => {
    //             return v.site_code === siteCode;
    //           });
              
    //           setCoordinates(j[0].gps_coordinates);
    //           setIconColor(j[0].color)
              
    //           var f = j[0].gps_coordinates.split(",");
    //          setLat(parseFloat(f[0])) ;
            
    //           setLon(parseFloat(f[1].slice(1, 10))) 
              
    //         });
    //     }
    //     getData()
    //   }, []);
  
  
  
  
  
  return (
    <>
    
  
    <GoogleMap
      defaultZoom={18}
      center={{ lat: 28.5626, lng: 77.2914 }}
      
      //   defaultOptions={{ styles: mapStyles }}
    >
      <Marker
        
        position={{
          lat: 28.5626,
          lng: 77.2914,
        }}
       
        icon={{
          url: "/images/bus-stop-earth.png",
          scaledSize: new window.google.maps.Size(50, 50),
        }}
      />

      
    </GoogleMap>
    
    </> 
  );
}

const MapWrapped = withScriptjs(withGoogleMap(Map));

export default function EarthingMap() {
  return (
    <div className="shadow-md mapstyle">
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
}
