import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

const style = {
  width: "100%",
  minWidth: 280,
  bgcolor: "background.paper",
};

export default function ListDividers(props) {
  return (
    <List
      sx={style}
      component="nav"
      aria-label="mailbox folders"
      className="shadow-md"
    >
      <ListItem dense>
        <ListItemText primary={props.t1} />
        <span className="colorssl"> {props.v1} </span>
      </ListItem>
      <Divider />
      <ListItem dense divider>
        <ListItemText primary={props.t2} />
        <span className='colorssl'>{props.v2}</span>
      </ListItem>
      <ListItem dense divider>
        <ListItemText primary={props.t3}/>
        <span className='colorssl'>{props.v3}</span>
      </ListItem>
      <ListItem dense divider>
        <ListItemText primary={props.t4} />
        <span className='colorssl'>{props.v4}</span>
      </ListItem>
      <ListItem dense divider>
        <ListItemText primary={props.t5}/>
        <span className='colorssl'>{props.v5}</span>
      </ListItem>

      
      
      <ListItem dense>
        <ListItemText primary={props.t7}/>
        <span className='colorssl'>{props.v7}</span>
      </ListItem>
    </List>
  );
}
