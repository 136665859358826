import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
  import faker from 'faker';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
  export const options = {
    responsive: false,
    maintainAspectRatio: true,
    
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Number of Calls',
      },
    },
  };
  
  const labels = ['April'];
  
const url = process.env.REACT_APP_URL


function CallsTrend() {
  const [ monthCalls, setMonthCalls] = useState(0)

  useEffect(()=>{
    axios.get(`${url}/api/v1/call/logs?startOffset=2022-08-01&endOffset=2022-08-31&imei=866557054011988&sites=`).then((response)=>{
    console.log(response.data.data); 
    setMonthCalls(Object.keys(response.data.data).length);
    })
  },[])

  const data = {
    labels,
    datasets: [
      {
        label: 'Number of Calls vs Month',
        data: "23",
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ],
  };

  return <Bar options={options} data={data} />;
}

export default CallsTrend;


