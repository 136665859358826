import * as React from "react";
import { useEffect } from "react";
import { styled } from "@mui/system";
import TablePaginationUnstyled from "@mui/base/TablePaginationUnstyled";
import axios from "axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
const url = process.env.REACT_APP_URL;

function createData(
  name,
  calories,
  fat,
  siteCode,
  timeOfCall,
  callDuration,
  callMode
) {
  return { name, calories, fat, siteCode, timeOfCall, callDuration, callMode };
}

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${theme.palette.mode === "dark" ? grey[900] : grey[100]};
  }
  `
);

const CustomTablePagination = styled(TablePaginationUnstyled)(
  ({ theme }) => `
  & .MuiTablePaginationUnstyled-spacer {
    display: none;
  }
  & .MuiTablePaginationUnstyled-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }
  & .MuiTablePaginationUnstyled-selectLabel {
    margin: 0;
  }
  & .MuiTablePaginationUnstyled-select {
    padding: 2px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    border-radius: 50px;
    background-color: transparent;
    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    }
    &:focus {
      outline: 1px solid ${
        theme.palette.mode === "dark" ? blue[400] : blue[200]
      };
    }
  }
  & .MuiTablePaginationUnstyled-displayedRows {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }
  & .MuiTablePaginationUnstyled-actions {
    padding: 2px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    border-radius: 50px;
    text-align: center;
  }
  & .MuiTablePaginationUnstyled-actions > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;
    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    }
    &:focus {
      outline: 1px solid ${
        theme.palette.mode === "dark" ? blue[400] : blue[200]
      };
    }
  }
  `
);

export default function UnstyledTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [arr, setArr] = React.useState([]);
  const [duration, setDuration] = React.useState(20);
  const [startOffset, setStartOffset] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endOffset, setEndOffset] = useState(
    new Date().toISOString().split("T")[0]
  );

  const handleChange = (event) => {
    setDuration(event.target.value);
    // setDuration(
    //   (event.target.value < 60 && event.target.value + "sec") ||
    //     (event.target.value > 60 &&
    //       Math.floor(event.target.value / 60) +
    //         "min " +
    //         (event.target.value % 60) +
    //         "sec")
    // );
  };

  useEffect(() => {
    // const d = new Date();
    // // var t = d.toISOString().split("T")[0];
    // // console.log(t);
    // // console.log(d);
    // var t = d.toISOString().split("T")[0];

    // setStartOffset(t)
    // setEndOffset(t)
    // console.log(t);
    // console.log("1", startOffset, endOffset);

    console.log("duration 1", duration);
    if (duration === 10) {
      const d = new Date();
      var t = d.toISOString().split("T")[0];
      axios
        .get(`${url}/api/v1/call/logs?startOffset=${t}&endOffset=${t}&sites=`)
        .then((response) => {
          setArr(response.data.data);
          let dataOfCalls = response.data.data;
          const newArray = dataOfCalls
            .slice(Math.max(dataOfCalls.length - 5, 0))
            .reverse();
        });

      setStartOffset(t);
      setEndOffset(t);
    } else if (duration === 20) {
      const d = new Date();
      const h = d.toISOString().split("T")[0];
      const sevenDaysBefore = d.setDate(d.getDate() - 7);
      const g = new Date(sevenDaysBefore);
      var t = g.toISOString().split("T")[0];
      console.log("3", t);
      console.log("4", h);
      console.log("7", startOffset, endOffset);
      setStartOffset(h);
      setEndOffset(t);
      console.log("5", startOffset, endOffset);

      axios
        .get(`${url}/api/v1/call/logs?startOffset=${t}&endOffset=${h}&sites=`)
        .then((response) => {
          setArr(response.data.data);
          let dataOfCalls = response.data.data;
          const newArray = dataOfCalls
            .slice(Math.max(dataOfCalls.length - 5, 0))
            .reverse();
        });
    } else if (duration === 30) {
      const d = new Date();
      var t = d.toISOString().split("T")[0];
      axios
        .get(
          `${url}/api/v1/call/logs?startOffset=2022-08-01&endOffset=${t}&sites=`
        )
        .then((response) => {
          setArr(response.data.data);
          let dataOfCalls = response.data.data;
          const newArray = dataOfCalls
            .slice(Math.max(dataOfCalls.length - 5, 0))
            .reverse();
        });
    }
  }, [duration]);

  const rows = arr;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div className="row">
        <Box sx={{ maxWidth: 300 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={duration}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value={10}>Today</MenuItem>
              <MenuItem value={20}>Last 7 days</MenuItem>
              <MenuItem value={30}>All</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div>
      <div className="row" style={{ width: "auto", overflow: "scroll" }}>
        <Root sx={{ maxWidth: "100%" }}>
          <table aria-label="custom pagination table">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Site Code</th>
                <th>Bus Stop</th>
                <th>Road</th>
                <th>Towards</th>

                <th>Time Of Call</th>
                <th>Duration(s)</th>
                <th>Mode</th>
              </tr>
            </thead>
            <tbody>
              {(rowsPerPage > 0
                ? rows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              ).map((row, index) => {
                var date1 = new Date(row.ts.slice(0, 19) + " UTC");
                var istOffset = 5 * 60 * 60 * 1000 + 30 * 60 * 1000; // 5 hours 30 minutes in milliseconds
                var dateIST = new Date(date1.getTime() - istOffset);
                
                // Format the date in IST
                var ws = dateIST.toString();
                var ds = ws.slice(0, 24);
                return (
                  <tr key={row.name}>
                    <td> {index + 1} </td>
                    <td style={{ width: 60 }} align="center">
                      {row.site_code}
                    </td>
                    <td>{row.bus_stop_name}</td>
                    <td style={{ width: 120 }} align="center">
                      {row.road_name}
                    </td>
                    <td style={{ width: 120 }} align="center">
                      {row.towards}
                    </td>

                    <td style={{ width: 120 }} align="center">

                      {/* {new Date(new Date(ds).getTime() - 5 * 60 * 60 * 1000 - 30 * 60 * 1000)} */}
                    
                    {ds}</td>
                    <td style={{ width: 40 }} align="center">
                      {/* {row.call_time} */}
                      {(row.call_time < 60 && row.call_time + "sec") ||
                        (row.call_time > 60 &&
                          Math.floor(row.call_time / 60) +
                            "min " +
                            (row.call_time % 60) +
                            "sec")}
                    </td>
                    <td style={{ width: 120 }} align="center">
                      {row.call_mode}
                    </td>
                  </tr>
                );
              })}

              {emptyRows > 0 && (
                <tr style={{ height: 41 * emptyRows }}>
                  <td colSpan={3} />
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <CustomTablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={7}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  componentsProps={{
                    select: {
                      "aria-label": "rows per page",
                    },
                    actions: {
                      showFirstButton: true,
                      showLastButton: true,
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </tr>
            </tfoot>
          </table>
        </Root>
      </div>
    </>
  );
}
