import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import NewButton from "./NewButton";

export default function LogsTable() {
  const [userData, setUserData] = React.useState([]);

  React.useEffect(() => {
    axios.get("https://trackcoredata.com/api/user_details").then((response) => {
      console.log(response.data.message);
      setUserData(response.data.message);
    });
  }, []);
  const columns = [
    {
      field: "photo_url",
      headerName: "Photo",
      width: 60,
      renderCell: (index) => {
        return (
          <div>
            <Avatar src={index.row.photo_url} />
          </div>
        );
      },
    },
    {
      field: "display_name",
      headerName: "Name",
      filterable: false,
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: false,
    },
    {
      field: "creation_time",
      headerName: "Creation Time",
      width: 200,
      editable: true,
    },
    {
      field: "last_login_time",
      headerName: "Last Login Time",
      width: 200,
      editable: true,
    },
    {
      field: "last_refresh_time",
      headerName: "Last Refresh Time",
      width: 200,
      editable: true,
    },
    {
      field: "email_verified",
      headerName: "Email Verified",
      width: 120,
      editable: true,
    },
    {
      field: "role",
      headerName: "Role",
      width: 120,
      editable: true,
    },
  ];

  return (
    <div style={{ height: 450, width: "100%", padding:"2%", marginBottom:"100px" }}>
      <div className="header">
        <div class="text-center mb-3 mt-4 three">
          <h1 className="d-inline-block">User Management</h1>
        </div>
        {/* <Button variant="contained" component={RouterLink} to="#">
          New User
        </Button> */}
        {/* <div
          class="modal fade"
          id="modalLoginForm"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold">Sign in</h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body mx-3">
                <div class="md-form mb-5">
                  <i class="fas fa-envelope prefix grey-text"></i>
                  <input
                    type="email"
                    id="defaultForm-email"
                    class="form-control validate"
                  />
                  <label
                    data-error="wrong"
                    data-success="right"
                    for="defaultForm-email"
                  >
                    Your email
                  </label>
                </div>

                <div class="md-form mb-4">
                  <i class="fas fa-lock prefix grey-text"></i>
                  <input
                    type="password"
                    id="defaultForm-pass"
                    class="form-control validate"
                  />
                  <label
                    data-error="wrong"
                    data-success="right"
                    for="defaultForm-pass"
                  >
                    Your password
                  </label>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button class="btn btn-default">Login</button>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <a
            href=""
            class="btn btn-default btn-rounded mb-4"
            data-toggle="modal"
            data-target="#modalLoginForm"
          >
            Launch New User
          </a>
        </div> */}
        <NewButton />
      </div>

      <DataGrid
        align="center"
        rows={userData}
        columns={columns}
        getRowId={(row) => row.display_name + row.email}
        pageSize={15}
        rowsPerPageOptions={[15]}
        disableSelectionOnClick
      />
    </div>
  );
}
