import React from 'react';
import { Helmet } from "react-helmet";
import Users from '../../Components/UserLogs/Users';
import PrimarySearchAppBar from '../../Components/Home/PrimarySearchAppBar';
import Footer from '../../Components/Home/Footer'
import MqttOverWebsocketConnection from '../../Components/Home/MqttOverWebsocketConnection';
import VerticalSlider from '../../Components/Home/VerticalSlider';
import Earthing from '../../Components/Earthing/Earthing';
import CallDetails from '../../Components/Ivdcrs/CallDetails';


function index(props) {
  return (
    <React.Fragment>
    <Helmet titleTemplate="PRSS | %s" title="Earthing" />
<PrimarySearchAppBar text = "Earthing Monitoring System"/>
 {/* <MqttOverWebsocketConnection/> */}

 <CallDetails/>
  
  </React.Fragment>
  );
}

export default index;
