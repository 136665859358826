import React, { useRef, useState, useContext, useEffect } from "react";

// import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useAuth } from "../../../context/AuthContext";
import { Link, useHistory } from "react-router-dom";
import AppContext from "../../../context/app-context";
import {
  FormLabel,
  FormControl,
  TextField,
  Button,
  Image,
  Typography,
} from "@mui/material";
import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(4),
  textAlign: "center",
  height: "100vh",
  color: theme.palette.text.secondary,
}));

const LoginPage = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigate = useHistory();

  const { login } = useAuth();
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(emailRef.current.value);
    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      navigate.push("/home");
    } catch {
      setError("Failed to log in");
    }

    setLoading(false);
  }
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const d = new Date();

  // const loginSubmitHandler = async (e) => {
  //   const BASE_URL =
  //     "https://cors-everywhere.herokuapp.com/http://frs.trackcoredata.com:2201/core_data/panic/login";

  //   const ADD_LOG =
  //     "https://cors-everywhere.herokuapp.com/http://frs.trackcoredata.com:2201/core_data/user/logs";

  //   const encodedString = Buffer.from(password).toString("base64");

  //   const data = {
  //     username: email,
  //     password: encodedString,
  //   };

  //   axios
  //     .post(BASE_URL, data)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         localStorage.setItem("status", response.data.status);
  //         history("/home");
  //         window.location.reload()
  //         setAuth(true);
  //       } else {
  //         alert("Wrong Credentials");
  //       }
  //     })
  //     .then(() => {
  //       const d = new Date();
  //       const myJSON = JSON.stringify(d);
  //       const data1 = {
  //         username: email,
  //         timestamp: myJSON,
  //         ip_address: "2402:e280:223a:338:d9d:e498:15d7:9c31",
  //       };
  //       axios.post(ADD_LOG, data1).then((response) => {
  //         console.log(response.status);
  //       });
  //     });
  // };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container sx={{ height: "100vh" }}>
          <Grid
            item
            sm={12}
            md={12}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              margin: "auto",
            }}
          >
            {matches && (
              <>
                <Box
                  className=" pt-3 bg-body rounded m-auto"
                  sx={{
                    bgcolor: "#ffffff",
                    width: "50%",
                  }}
                >
                  <img
                    src="./images/logocd.png"
                    alt="brand"
                    width="45%"
                    height="auto"
                    className="d-block m-auto text-center"
                  />
                  <br />
                  {/* <Typography
                    className="font-login text-center"
                    variant="h3"
                    sx={{ fontsize: "3rem" }}
                    style={{ color: "#253252" }}
                  >
                    <b>Public Response Support System</b>
                  </Typography> */}
                </Box>
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: "50%",
                    margin: "auto",
                  }}
                >
                  <FormControl
                    onSubmit={handleSubmit}
                    className="shadow-lg p-5  bg-body rounded"
                    margin="normal"
                    variant="outlined"
                    fullWidth={true}
                    sx={{ gap: 6 }}
                  >
                  
                    <Typography
                    className="font-login text-center"
                    variant="h3"
                    sx={{ fontsize: "3rem" }}
                    style={{ color: "#253252" }}
                  >
                    <b>Public Response Support System</b>
                  </Typography>
                    <TextField required label="Username" inputRef={emailRef} />
                    <TextField
                      required
                      label="Password"
                      type="Password"
                      inputRef={passwordRef}
                    />
                    <Button onClick={handleSubmit} variant="contained">
                      Sign in
                    </Button>
                  </FormControl>
                </Box>
              </>
            )}
            {!matches && (
              <>
                <Box
                  className=" p-3 mb-5 bg-body rounded m-auto"
                  sx={{ bgcolor: "#ffffff", width: "75%" }}
                >
                  <item>
                    <img
                      src="./images/logocd.png"
                      alt="brand"
                      width="45%"
                      height="auto"
                      className="d-block m-auto text-center"
                    />
                  </item>
                  <Typography
                    className="font-login text-center"
                    variant="h4"
                    sx={{ fontsize: "3rem" }}
                    style={{ color: "#253252" }}
                  >
                    <b>Public Response Support System</b>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: "75%",
                    margin: "auto",
                  }}
                >
                  <FormControl
                    onSubmit={handleSubmit}
                    className="shadow-lg p-3 mb-5 bg-body rounded"
                    margin="normal"
                    variant="outlined"
                    fullWidth={true}
                    sx={{ gap: 6, padding: 2 }}
                  >
                    <Typography variant="h3" sx={{ color: "primary.main" }}>
                      Sign in
                    </Typography>
                    <TextField required label="Username" inputRef={emailRef} />
                    <TextField
                      required
                      label="Password"
                      type="Password"
                      inputRef={passwordRef}
                    />
                    <Button onClick={handleSubmit} variant="contained">
                      Sign in
                    </Button>
                  </FormControl>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default LoginPage;
