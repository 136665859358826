import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import axios from "axios";

const url = process.env.REACT_APP_URL;
export default function Verification(props) {
  const [basicModal, setBasicModal] = useState(false);

  const toggleShow = () => setBasicModal(!basicModal);
  //   const submitForm = (event) => {
  //     event.preventDefault();
  //     console.log(event.target[0].value);
  //   };
  return (
    <>
      <MDBBtn onClick={toggleShow} className="btn btn-default btn-rounded mb-4">
        Verify
      </MDBBtn>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="text-center">
                Verify Field Visit Data
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <div className="row">
              <div className="col-md-6 text-center">
                <img height={200} width={200}  src={props.image1} alt="No Image" />
              </div>
              <div className="col-md-6 text-center">
                <img height={200} width={200}  src={props.image2} alt="No Image" />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6 text-center">
                <img height={200} width={200} src={props.image3} alt="No Image" />
              </div>
              <div className="col-md-6 text-center">
                <img height={200} width={200}  src={props.image4} alt="No Image" />
              </div>
            </div>
            <form
              onSubmit={props.submitForm}
            >
              <MDBModalBody>
                <div class="modal-body mx-3">
                  <div class="md-form mb-4 d-flex">
                    <i
                      class="fas fa-envelope prefix grey-text"
                      style={{ margin: "auto 10px", width: "20px" }}
                    ></i>
                    <label htmlFor="">Pre Visit remarks</label>
                    <br />
                    <input
                      type="text"
                      id="defaultForm-email"
                      class="form-control validate"
                      placeholder="Pre Visit Remarks"
                      defaultValue={props.previsit}
                      style={{
                        borderRight: "0px",
                        borderTop: "0px",
                        borderLeft: "0px",
                        boxShadow: "none",
                      }}
                    />
                  </div>

                  <div class="md-form mb-4 d-flex">
                    <i
                      class="fas fa-lock prefix grey-text"
                      style={{ margin: "auto 10px ", width: "20px" }}
                    ></i>
                    <input
                      type="password"
                      id="defaultForm-pass"
                      class="form-control validate"
                      placeholder="Password"
                      style={{
                        borderRight: "0px",
                        borderTop: "0px",
                        borderLeft: "0px",
                        boxShadow: "none",
                      }}
                    />
                  </div>

                  <div class="md-form mb-4 d-flex">
                    <i
                      class="fas fa-file-signature prefix grey-text"
                      style={{ margin: "auto 10px ", width: "20px" }}
                    ></i>
                    <input
                      type="text"
                      id="defaultForm-name"
                      class="form-control validate"
                      placeholder="Display Name"
                      style={{
                        borderRight: "0px",
                        borderTop: "0px",
                        borderLeft: "0px",
                        boxShadow: "none",
                      }}
                    />
                  </div>

                  <div class="md-form mb-4 d-flex">
                    <i
                      class="fas fa-mobile prefix grey-text"
                      style={{ margin: "auto 10px ", width: "20px" }}
                    ></i>
                    <input
                      type="text"
                      id="defaultForm-number"
                      class="form-control"
                      placeholder="Mobile number"
                      style={{
                        borderRight: "0px",
                        borderTop: "0px",
                        borderLeft: "0px",
                        boxShadow: "none",
                      }}
                    />
                  </div>

                  <div class="md-form mb-4 d-flex">
                    <i
                      class="fas fa-universal-access prefix grey-text"
                      style={{ margin: "auto 10px ", width: "20px" }}
                    ></i>
                    <input
                      type="text"
                      id="defaultForm-disabled"
                      class="form-control validate"
                      placeholder="Disabled(True/False)"
                      style={{
                        borderRight: "0px",
                        borderTop: "0px",
                        borderLeft: "0px",
                        boxShadow: "none",
                      }}
                    />
                  </div>

                  <div class="md-form mb-4 d-flex">
                    <i
                      class="fas fa-file-image prefix grey-text"
                      style={{ margin: "auto 10px ", width: "20px" }}
                    ></i>
                    <input
                      type="text"
                      id="defaultForm-photo"
                      class="form-control validate"
                      placeholder="Photo URL"
                      style={{
                        borderRight: "0px",
                        borderTop: "0px",
                        borderLeft: "0px",
                        boxShadow: "none",
                      }}
                    />
                  </div>

                  <div class="md-form mb-4 d-flex">
                    <i
                      class="fas fa-bolt prefix grey-text"
                      style={{ margin: "auto 10px ", width: "20px" }}
                    ></i>
                    <input
                      type="text"
                      id="defaultForm-role"
                      class="form-control validate"
                      placeholder="Role"
                      style={{
                        borderRight: "0px",
                        borderTop: "0px",
                        borderLeft: "0px",
                        boxShadow: "none",
                      }}
                    />
                  </div>
                </div>
              </MDBModalBody>

              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleShow}>
                  Close
                </MDBBtn>
                <MDBBtn type="submit">Create User</MDBBtn>
              </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
