import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { TextField } from "@material-ui/core";
import { useState, useEffect } from "react";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function WardModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button sx={{ textTransform: "none" }} onClick={handleOpen}>
        {props.block}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="header text-center">
            <div class="text-center mb-3 three">
              <h1 className="d-inline-block">Ward update</h1>
            </div>
          </div>
          <div className="form mt-4">
            <div className="row">
              <div className="col-lg-6">
                <TextField
                  required
                  id="outlined-required"
                  label="Ward"
                  defaultValue=""
                  //   onChange={(e) => setNewVersion(e.target.value)}
                />
              </div>
            </div>
            <div className="bu mt-4 text-center">
              <Button
                // onClick={deleteDevice}
                color="warning"
                variant="contained"
              >
                Submit
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
