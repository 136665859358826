import React, { PureComponent } from 'react';
import { PieChart,Label, Legend, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';


const data01 = [
  { name: 'Total No. of Calls', value: 9 },
  { name: 'Incoming', value: 3 },
  { name: 'Outgoing', value: 6 },
 
];


  const renderLegend = (props) => {
    const { payload } = props;
  
    return (
      <ul>
        {
          data01.map((entry, index) => (
            <li style={{"color":"#82ca9d"}} key={`item-${index}`}>{entry.name} : {entry.value}</li>
          ))
        }
      </ul>
    );
  }

export default class Examples extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/pie-chart-of-two-levels-gor24';
  

  render() {
    return (
     
        <PieChart width={400} height={250}>
            
            <Legend content={renderLegend}  verticalAlign="top" height={36}/>
            
          {/* <Pie  data={data01} dataKey="value" cx="50%" cy="50%" outerRadius={60} fill="#8884d8" /> */}
          <Pie data={data01} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#82ca9d" label />
        </PieChart>
      
    );
  }
}
