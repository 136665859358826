import React, {useContext} from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
  import faker from 'faker';
  import AppContext from "../../../context/app-context";

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Number of Calls in a day',
      },
    },
  };
 

function IncomingVsOutgoing(props) {
    const { orgStartDate, orgEndDate ,nc, setNc, dateArray} = useContext(AppContext);
    
    const labels = dateArray

 const data = {
      labels,
      datasets: [
        {
          label: 'Calls',
          data: props.vf,
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        
      ],
    };
  return <Bar options={options} data={data} />;
}

export default IncomingVsOutgoing