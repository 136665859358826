import React from 'react'
import Chart from "react-google-charts";

function SingleLineChart() {
    return (
    <>
    <Chart
  width={'100%'}
  height={'500'}
  chartType="Line"
  loader={<div>Loading Chart</div>}
  data={[
    [
      { type: 'date', label: 'Day' },
      'Calls',
    ],
    [new Date(2021, 6, 23), 2],
    [new Date(2021, 7, 12), 3],
    [new Date(2021, 9), 6],
    [new Date(2021, 10), 9],
    [new Date(2021, 11), 7],
   
  ]}
  options={{
    chart: {
      title:
        'Total number of Calls in a day ',
    },
    width: 900,
    height: 500,
    series: {
      // Gives each series an axis name that matches the Y-axis below.
      0: { axis: 'Temps' },
      1: { axis: 'Daylight' },
    },
    axes: {
      // Adds labels to each axis; they don't have to match the axis names.
      y: {
        Temps: { label: 'Calls per day' },
        Daylight: { label: 'Daylight' },
      },
    },
  }}
  rootProps={{ 'data-testid': '4' }}
/>
    </>
    )
}

export default SingleLineChart;
