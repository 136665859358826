import React, { useContext, useState, useEffect } from "react"
import { auth } from "../firebase"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function login(email, password) {
    localStorage.setItem('user', email);
    return auth.signInWithEmailAndPassword(email, password)
  }

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
  
    // New function to login using a token stored in cookies
    const loginWithToken = async ()=> {
      const token = getCookie('customToken');
      
      if (!token) {
        return Promise.reject(new Error('No token found'));
      }
  
      // Sign in with the custom token
      return auth.signInWithCustomToken(token)
        .then((userCredential) => {
          const user = userCredential.user;
          setCurrentUser(user);
  
          // Save the user's email in localStorage
          localStorage.setItem('user', user.email);
  
          return user;
        })
        .catch((error) => {
          console.error("Error logging in with token:", error);
          return Promise.reject(error);
        });
    }
    
    const LoginWithToken = async () => { 
        try{
          await loginWithToken()
  
  
        }
        catch(err){
          console.log(err);
          
        }
  
    }

  function logout() {
    localStorage.clear();
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }



  useEffect(() => {
    LoginWithToken()
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
