import React, { useEffect } from "react";
import { io } from "socket.io-client";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  FormLabel,
  FormControl,
  TextField,
  Button,
  Image,
  Typography,
} from "@mui/material";
import { useState } from "react";

function Socket() {
  const [a, setA] = useState("");

  const socket = io("http://localhost:3000");
  socket.on("connect", () => {
    console.log("hi");
    console.log(socket.id); // x8WIv7-mJelg7on_ALbx
  });

  // previous code commented
  // function sendSocketData() {
  //   return 
  // }
 
  // setInterval(() => {
  //   socket.emit("get_panic_data", "satyam");
  // }, 1000);
  

  // socket.on("connect", () => {
  //   console.log(socket.connected); // true
  // });

  // socket.on("logs", (msg) => {});

  // socket.on("panic_data", (msg) => {
  //   console.log("hi jsdhkjl");
  //   console.log(msg.data);
  //   console.log(typeof msg.data);
  //   const socket_data = JSON.parse(msg.data);
  //   console.log(socket_data);
  //   setA(socket_data.values.callTime);
  // });

  
  return (
    <div>
      {/* <form id="emit" method="POST" action='#'>
    <input type="text" name="emit_data" id="emit_data" placeholder="Message"/>
    <input type="submit" value="Echo"/>
</form> */}
      <h1>hi </h1>
      <h2> check it man </h2>
    </div>
  );
}

export default Socket;
