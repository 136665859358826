import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button sx={{"textTransform":"none"}} onClick={handleOpen}>Images</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Installation Images
          </Typography>
         
         <div className="row">
         <Typography id="modal-modal-description" sx={{ mt: 2, mb:2 }}>
           Pre-Installation Images:- 
          </Typography>
            <div className="col-md-6">
                
            <img style={{"width":"70%"}} src={props.url1} alt="" />
            </div>
            <div className="col-md-6">
            <img style={{"width":"70%"}} src={props.url2} alt="" />
            </div>
         </div>
         <div className="row">
         <Typography id="modal-modal-description" sx={{ mt: 2, mb:2 }}>
           Post-Installation Images:- 
          </Typography>
            <div className="col-md-6">
                
            <img style={{"width":"70%"}} src={props.url3} alt="" />
            </div>
            <div className="col-md-6">
            <img style={{"width":"70%"}} src={props.url4} alt="" />
            </div>
         </div>
          
        </Box>
      </Modal>
    </div>
  );
}
