import React, { Component } from "react";
import { Helmet } from "react-helmet";
import LoginPage from "../../Components/Login/Login";


class About extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet titleTemplate="PRSS | %s" title="Login" />

        <LoginPage />
        
      </React.Fragment>
    );
  }
}

export default About;