import * as React from 'react';
import { useContext } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AppContext from "../../../context/app-context";

export default function ImageTypeSelect(props) {
  
    const { setAge } = useContext(AppContext);
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Box sx={{ maxWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Age</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.age}
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value={"drill_hole_image"}>Drill Hole</MenuItem>
          <MenuItem value={"rabbit_nut_image"}>Rivet Nut</MenuItem>
          <MenuItem value={"final_image_1"}>Final Image 1</MenuItem>
          <MenuItem value={"final_image_2"}>Final Image 2</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}