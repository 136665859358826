import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import AppContext from "../../../context/app-context";
import { useEffect, useContext } from 'react';

const url = process.env.REACT_APP_URL


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};



export default function MultipleSelectCheckmarks() {
  const { setData,setAddresses,addresses, data, busStop, setBusStop } =
    useContext(AppContext);
  
 
useEffect(()=>{
  axios.get(`${url}/core_data/all/site/address`).then((response)=>{
    
    setAddresses(response.data.data);
  })
}, [])
  
// var unique = data.filter((value, index) => {
//   console.log(value.bus_stop_name);
//   return data.indexOf(value.bus_stop_name) === data.lastIndexOf(value.bus_stop_name);
// });

// const ae = data.filter((value, index, self) =>
//   index === self.findIndex((t) => (
//     t.place === value.bus_stop_name && t.name === value.bus_stop_name
//   ))
// )
console.log(data);
  const names = addresses;
  

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setBusStop(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 120 }}>
        <InputLabel id="demo-multiple-checkbox-label">Bus Stop</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={busStop}
          onChange={handleChange}
          input={<OutlinedInput label="Bus Stop" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem key={name.id} value={name.bus_stop_name}>
              <Checkbox checked={busStop.indexOf(name.bus_stop_name) > -1} />
              <ListItemText primary={name.bus_stop_name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
