import React, { useContext, useEffect, useState } from "react";

import {  Route, useHistory, BrowserRouter, Switch, Redirect} from "react-router-dom";

import Home from "../App/Screens/Home";
import Login from "../App/Screens/Login";
import Reports from "../App/Screens/Reports";
import Reducer from "../App/Components/Home/Reducer";
import AppState from "../context/AppState";
import Chart1 from "../App/Components/Home/Chart1";
import UserLogs from "../App/Screens/UserLogs";
import Admin from "../App/Screens/Admin";
import IndividualDevice from "../App/Screens/Device";
import TelemetryLogs from "../App/Screens/TelemetryLogs";
import { AuthProvider } from "../context/AuthContext";
// import PrivateRoute from "../App/Components/PrivateRoute";
import Profile from "../App/Screens/Account";
import Weight from "../App/Screens/Weight";
import Users from "../App/Screens/Users";
import Table from "../App/Screens/NewTable";
import Wsms from "../App/Screens/Wsms";
import Earthing from "../App/Screens/Earthing";
import Ivdcrs from "../App/Screens/Ivdcrs";
import Video from "../App/Screens/Video";

// const ProtectedRoute = () => {
//   const isLoggedIn = localStorage.getItem("user"); // Check for user status in localStorage

//   // If the user is logged in, render the child component (Outlet renders the matched child route)
//   return isLoggedIn ? <Outlet /> : <Navigate to="/" replace />;
// };

// function AuthRoute({ children }) {
//   // const { currentUser } = useAuth();
//   const isLoggedIn = localStorage.getItem("user");
//   const [user, setUser] = useState(localStorage.getItem('user'));
//   const history = useNavigate();

//   useEffect(() => {
//     // Function to check for user in localStorage
//     const checkUser = () => {
//       const storedUser = localStorage.getItem('user');
//       if (storedUser) {
//         setUser(storedUser);
//         // history('/home');
//         clearInterval(interval); // Clear interval once user is found
//       }
//     };

//     // Set an interval to check for user every second (1000ms)
//     const interval = setInterval(checkUser, 1000);

//     // Cleanup interval on component unmount
//     return () => clearInterval(interval);
//   }, [history]);
  
//   return user ? children : <Navigate to="/" />;
// }

function PrivateRoute({ children, ...rest }) {

  const user = localStorage.getItem('user')


  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}


function AuthRoute({ children, ...rest }) {
  const [user, setUser] = useState(localStorage.getItem('user'));
  const history = useHistory();

  useEffect(() => {
    // Function to check for user in localStorage
    const checkUser = () => {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        setUser(storedUser);
        history.push('/home');
        clearInterval(interval); // Clear interval once user is found
      }
    };

    // Set an interval to check for user every second (1000ms)
    const interval = setInterval(checkUser, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [history]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !user ? (
          children
        ) : (
          // The user exists, redirect to dashboard
          history.push('/home')
        )
      }
    />
  );
}


// function RedirectHandler() {
//   const user = localStorage.getItem('user');
//   return (
//     <Redirect to={user ? "/home" : "/"} />
//   );
// }

function RedirectHandler() {
  const user = localStorage.getItem('user');
  return (
    <Redirect to={user ? "/home" : "/"} />
  );
}

export default function App() {
  // const abc = localStorage.getItem("status");
  const isLoggedIn = localStorage.getItem("user");
  


  
  return (
    // <>
    //   <AuthProvider>
    //     <AppState>
    //       <Routes>
    //         <Route path="/" element={<AuthRoute><Login /></AuthRoute>} />
    //         <Route element={<ProtectedRoute />}>
    //         <Route path="/user-management" element={<Users />} />
    //         <Route
    //           path="/home"
    //           element={
    //             <PrivateRoute>
    //               <Home />
    //             </PrivateRoute>
    //           }
    //         />
    //         <Route
    //           path="/video"
    //           element={
    //             <PrivateRoute>
    //               <Video />
    //             </PrivateRoute>
    //           }
    //         />
    //         <Route
    //           path="reports"
    //           element={
    //             <PrivateRoute>
    //               <Reports />
    //             </PrivateRoute>
    //           }
    //         />
    //         <Route path="/weight" element={<Weight />} />
    //         <Route path="/earthing" element={<Earthing />} />
    //         <Route path="/profile" element={<Profile />} />
    //         <Route path="/table" element={<Table />} />
    //         <Route path="/ivdcrs" element={<Ivdcrs />} />
    //         <Route
    //           path="/logs"
    //           element={
    //             <PrivateRoute>
    //               <UserLogs />
    //             </PrivateRoute>
    //           }
    //         />
    //         <Route
    //           path="/admin"
    //           element={
    //             <PrivateRoute>
    //               <Admin />
    //             </PrivateRoute>
    //           }
    //         />
    //         <Route
    //           path="/device/:siteCode"
    //           element={
    //             <PrivateRoute>
    //               <IndividualDevice />
    //             </PrivateRoute>
    //           }
    //         />
    //         <Route
    //           path="/admin/telemetry-logs"
    //           element={
    //             <PrivateRoute>
    //               <TelemetryLogs />{" "}
    //             </PrivateRoute>
    //           }
    //         />
    //         <Route
    //           path="/admin/wsms"
    //           element={
    //             <PrivateRoute>
    //               <Wsms />{" "}
    //             </PrivateRoute>
    //           }
    //         />
    //         </Route>
    //       </Routes>
    //     </AppState>
    //   </AuthProvider>
    // </>

<AppState>
<AuthProvider>
  <BrowserRouter>
      <Switch>
          <AuthRoute exact path="/">
              <Login />
          </AuthRoute>
          <PrivateRoute path="/user-management">
              <Users />
          </PrivateRoute>
          <PrivateRoute path="/home">
              <Home />
          </PrivateRoute>
          <PrivateRoute path="/reports">
              <Reports />
          </PrivateRoute>
          <PrivateRoute path="/weight">
              <Weight />
          </PrivateRoute>
          <PrivateRoute path="/earthing">
          <Earthing />
          </PrivateRoute>
          <PrivateRoute path="/profile">
          <Profile />
          </PrivateRoute>
          <PrivateRoute path="/table">
          <Table />
          </PrivateRoute>
          <PrivateRoute path="/ivdcrs">
          <Ivdcrs />
          </PrivateRoute>
          <PrivateRoute path="/logs">
          <UserLogs />
          </PrivateRoute>
          <PrivateRoute path="/admin">
          <Admin />
          </PrivateRoute>
          <PrivateRoute path="/device/:siteCode">
          <IndividualDevice />
          </PrivateRoute>
          <PrivateRoute path="/admin/telemetry-logs">
          <TelemetryLogs />
          </PrivateRoute>
          <PrivateRoute path="/admin/wsms">
              <Wsms />
          </PrivateRoute>
          <RedirectHandler path="*">
          </RedirectHandler>
      </Switch>
  </BrowserRouter>
  </AuthProvider>
  </AppState>
  );
}
