import React from "react";
import ReactSpeedometer from "react-d3-speedometer";

function Weightometer(props) {
  return (
    <div>
      <ReactSpeedometer
        maxValue={100}
        value={98}
        needleColor="#F68B1E"
        needleTransitionDuration={4000}
        needleTransition="easeElastic"
        segments={5}
        segmentColors={["#bf616a", "#d08770", "#ebcb8b", "#a3be8c", "#b48ead"]}
        height={200}
      />
    </div>
  );
}

export default Weightometer;
