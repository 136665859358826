import React, { useEffect, useState } from "react";
import axios from "axios";
import Example from "./CallReportChart";
import Grid from "@mui/material/Grid";
import DatePicker from "react-datepicker";
import { subDays, addDays } from "date-fns";
import Examples from "./DevicePieChart";
import SimplePieChart from "./SimplePieChart";
import Doughnut from "./Doughnut";
import { useParams } from "react-router-dom";

const url = process.env.REACT_APP_URL

function GraphSections(props) {
  const { siteCode } = useParams();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dataOfNumberOfCalls, setDataOfNumberOfCalls] = useState([])

  var res = startDate.toISOString().substring(0, 10);
  
  var rese = endDate.toISOString().substring(0, 10);
  function incr_date(date_str){
    var parts = date_str.split("-");
    var dt = new Date(
      parseInt(parts[0], 10),      // year
      parseInt(parts[1], 10) - 1,  // month (starts with 0)
      parseInt(parts[2], 10)       // date
    );
    dt.setDate(dt.getDate() + 1);
    parts[0] = "" + dt.getFullYear();
    parts[1] = "" + (dt.getMonth() + 1);
    if (parts[1].length < 2) {
      parts[1] = "0" + parts[1];
    }
    parts[2] = "" + dt.getDate();
    if (parts[2].length < 2) {
      parts[2] = "0" + parts[2];
    }
    return parts.join("-");
  }
  const resultOfIncreament = incr_date(rese)
  useEffect(()=>{
    axios.get(`${url}/api/v1/number_of_calls?site=${siteCode}&startOffset=${res}&endOffset=${resultOfIncreament}`).then((response)=>{
      console.log(response.data.data);
      setDataOfNumberOfCalls(response.data.data)
    })
    
    
    
    
  
    },[startDate,endDate])

  
  return (
    <>
      <div className="row mt-3">
        <div className="col mt-4">
          <h1 className="colorssl">Select Range :-</h1>
          <label for="birthday">From:</label>
          <DatePicker
            selected={startDate}
            excludeDateIntervals={[
              {
                start: subDays(new Date("2021/02/08"), 0),
                end: addDays(new Date("2022/01/15"), 0),
              },
            ]}
            onChange={(date) => setStartDate(date)}
          />

          <br />
          <br />
          <label for="birthday">To: </label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
          />
        </div>
        <div className="col shadow-md mx-4">
        <h1 className="colorssl text-center mt-2">No. of Calls vs Date :-</h1>
         <Example  
         data = {dataOfNumberOfCalls}
         height = {250}
         width = {500}
         />
        </div>
        <div className="col-lg-4 shadow-md">
        <h1 className="colorssl text-center mt-2">Total Logs</h1>
         <Examples />
        </div>
      </div>
    </>
  );
}

export default GraphSections;
