import React, {useContext} from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import AppContext from "../../../context/app-context";


function LogTable(props) {


  
  return <>
  <tr>
      <th scope="row"> {props.ind} </th>
      <td> {props.user} </td>
      <td> {props.time} </td>
      
    </tr> 
  </>;
}

export default LogTable;
