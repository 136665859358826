import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import FirmwareModal from "./FirmwareModal";
import { getAuth } from "firebase/auth";

const url = process.env.REACT_APP_URL;

export default function FirmwareTable() {
  const [deviceData, setDeviceData] = React.useState([]);
  const auth = getAuth();
  const user = auth.currentUser;
  const name = user.providerData[0].email;

  React.useEffect(() => {
    axios.get(`${url}/core_data/all/devices`).then((response) => {
      console.log(response.data.data);
      setDeviceData(response.data.data);
    });
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "S.No.",
      filterable: false,
      width: 60,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "site_code",
      headerName: "Site Code",
      width: 240,
      editable: true,
    },
    {
      field: "imei",
      headerName: "IMEI Number",
      width: 400,
      editable: true,
    },
    {
      field: "upgrade",
      headerName: "Upgrade",
      width: 440,
      editable: true,
      renderCell: (params) => {
        return (
          <FirmwareModal
            imei={params.row.imei}
            personInstalled={name}
            siteCode={params.row.site_code}
          />
        );
      },
    },
  ];

  return (
    <div style={{ height: 450, width: "100%", marginTop: "170px" }}>
      <div className="header">
        <div class="text-center mb-3 mt-4 three">
          <h1 className="d-inline-block">Firmware Upgrade</h1>
        </div>
      </div>
      <DataGrid
        align="center"
        rows={deviceData}
        columns={columns}
        pageSize={15}
        rowsPerPageOptions={[15]}
        disableSelectionOnClick
      />
    </div>
  );
}
