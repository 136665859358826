import * as React from "react";
import SelectOption from "../Home/SelectOption";
import { useState, useContext, useEffect } from "react";
import TableRowe from "../Home/TableRowe";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AppContext from "../../../context/app-context";
import Grid from "@mui/material/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Container from "@mui/material/Container";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import PieChart from "./PieChart";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pdf from "react-to-pdf";
import { subDays, addDays } from "date-fns";
import SingleLineChart from "../Home/SingleLineChart";
import IncomingVsOutgoing from "./IncomingVsOutgoing";
import MultipleSelectCheckmarks from "./MultipleSelectCheckmarks";
import moment from "moment";
import Road from "./Road";
import Towards from "./Towards";
import SiteCode from "./SiteCode";
import CheckboxesTags from "./SearchSelect";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const url = process.env.REACT_APP_URL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflowY: "scroll",
  p: 4,
};
const siteCodes = [{ value: "chocolate", label: "C-22-P" }];
const busStops = [{ value: "chocolate", label: "Palika Kendra" }];
const roads = [{ value: "chocolate", label: "Sansad Marg" }];
const towardss = [{ value: "chocolate", label: "Parliament House" }];
function LocationCard(props) {
  const { sx, ...other } = props;

  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        color: "white",
        p: 1,
        m: 1,
        borderRadius: 1,
        textAlign: "center",
        fontSize: "1rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}
const ref = React.createRef();
const ref1 = React.createRef();

const Reports = () => {
  const {
    orgStartDate,
    setOrgStartDate,
    setOrgEndDate,
    setDateArray,
    road,
    siteCode,
    towards,
    busStop,
    setBusStop,
    setRoad,
    setTowards,
    setSiteCode,
    setAddresses,
    addresses,
    sites,
    setSites,
    nc,
    setNc,
  } = useContext(AppContext);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [data, setData] = useState([]);
  const [showDiv, setShowDiv] = useState(false);
  const [showLogo, setShowLogo] = useState(false);

  // const [siteCode, setSiteCode] = React.useState("");
  const [numberOfCalls, setNumberOfCalls] = useState(0);
  const [incomingCalls, setIncomingCalls] = useState(0);
  const [outgoingCalls, setOutgoingCalls] = useState(0);
  const [sum, setSum] = useState(0);
  const [onSelectSite, setOnSelectSite] = useState([]);
  const [incomingDuration, setIncomingDuration] = useState(0);
  const printRef = React.useRef();

  const options = {
    orientation: "landscape",
    unit: "in",
    format: [15, 10],
  };
  // const handleChangedBusStop = (event) => {
  //   setBusStop(event.target.value);
  // };

  // const handleChangedTowards = (event) => {
  //   setTowards(event.target.value);
  // };
  // const handleChangedSiteCode = (event) => {
  //   setSiteCode(event.target.value);
  // };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  var res = startDate.toISOString().substring(0, 10);
  var stringii = res.slice(8) + "-" + res.slice(5, 8) + res.slice(0, 4);
  var rese = endDate.toISOString().substring(0, 10);
  var fringii = rese.slice(8) + "-" + rese.slice(5, 8) + rese.slice(0, 4);

  function getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }
    return dateArray;
  }

  const newArrayOfDates = getDates(res, rese);

  console.log(busStop[0]);
  console.log(road[0]);
  console.log(towards[0]);
  console.log(addresses);

  useEffect(() => {
    var sitedf = [];
    for (let index = 0; index < towards.length; index++) {
      var d = addresses.filter((v) => {
        return (
          v.towards === towards[index] &&
          v.bus_stop_name === busStop[index] &&
          v.road_name === road[index]
        );
      });
      const selectedSiteCode = d[0].site_code;
      sitedf.push(selectedSiteCode);
    }
    console.log(sitedf);
    var dfr = sitedf.toString();
    setOnSelectSite(dfr);
    // if (d.length > 0) {
    //   console.log("true");
    //   console.log(d);
    //   const selectedSiteCode = d[0].site_code
    //   console.log(selectedSiteCode);
    //   console.log(typeof selectedSiteCode);
    //   setOnSelectSite(selectedSiteCode)
    //   console.log(onSelectSite);
    // }
  }, [onSelectSite, road, busStop, towards]);

  // }
  console.log(sites);
  var BASE_URL = `${url}/api/v1/call/logs?startOffset=${res}&endOffset=${rese}&sites=${onSelectSite}${sites}`;

  async function statistics() {
    const jh = [];

    const f = newArrayOfDates.map((simdata) => {
      axios
        .get(
          `${url}/api/v1/call/logs?startOffset=${simdata}&endOffset=${simdata}`
        )
        .then((response) => {
          // jh.push(response.data.data.length);
        });
    });
    function dd() {
      return jh;
    }
    async function ddd() {
      const result = await dd();

      console.log(f);
      setNc(jh);
    }
    ddd();
  }
  React.useEffect(() => {}, []);
  function getDataForReport() {
    console.log(BASE_URL);
    axios.get(BASE_URL).then((response) => {
      statistics();
      setNumberOfCalls(Object.keys(response.data.data).length);
      setDateArray(newArrayOfDates);
      setData(response.data.data);
      setShowDiv(true);
      const newArray = response.data.data.filter(function (singleData) {
        return singleData.call_mode === "Incoming";
      });
      const otherArray = response.data.data.filter(function (singleData) {
        return singleData.ts.slice(7, 10) === "15";
      });

      const incomingVsOutgoing = response.data.data.filter(function (
        singleData
      ) {
        return singleData.ts;
      });

      setIncomingCalls(Object.keys(newArray).length);

      var newNumber = 0;
      response.data.data.map((singleData) => {
        newNumber += singleData.call_time;
      });
      setSum(newNumber);
      var incomingDuration1 = 0;
      newArray.map((singleData) => {
        incomingDuration1 += singleData.call_time;
      });
      setIncomingDuration(incomingDuration1);
    });
  }

  function waveHello() {
    setShowLogo(true);
  }

  function createTablerow(singleData, index) {
    var date1 = new Date(singleData.ts.slice(0, 19));
    var ws = date1.toString();
    var ds = ws.slice(0, 24);

    return (
      <TableRowe
        key={index}
        in={index + 1}
        siteCode={singleData.site_code}
        imei={singleData.access_token}
        // call_time={singleData.call_time}
        call_time={
          (singleData.call_time < 60 && singleData.call_time + "sec") ||
          (singleData.call_time > 60 &&
            Math.floor(singleData.call_time / 60) +
              "min " +
              (singleData.call_time % 60) +
              "sec")
        }
        call_mode={singleData.call_mode}
        timeOfCall={ds}
      />
    );
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      backgroundColor: "#9CA2B2",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  // 2022-12-01
  //2022-01-27

  return (
    <div>
      <Container className="mt-4 " maxWidth="lg">
        <div className="head mt-4 mb-4 text-center">
          <img
            style={{ width: "4%" }}
            src="/images/phone-call.png"
            alt="call logs"
          />
          <span className="logs mx-4"> Logs </span>
        </div>

        <Box sx={{ flexDirection: "row" }}>
          <div className="row mb-4 mt-4 text-center">
            <div className="col-lg-3 text-center d-flex">
              <img
                style={{ width: "15%", height: "auto" }}
                src="/images/map.png"
                alt=""
              />
              <CheckboxesTags />
            </div>
            <div className="col-lg-3 text-center d-flex">
              <img
                style={{ width: "15%", height: "auto" }}
                src="/images/bus-stop.png"
                alt=""
              />
              <MultipleSelectCheckmarks />
            </div>
            <div className="col-lg-3 text-center d-flex">
              <img
                style={{ width: "15%", height: "auto" }}
                src="/images/road.png"
                alt=""
              />
              <Road />
            </div>
            <div className="col-lg-3 text-center d-flex">
              <img
                style={{ width: "15%", height: "auto" }}
                src="/images/signpost.png"
                alt=""
              />
              <Towards />
            </div>
          </div>

          {/* Second Row */}
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Grid item xs={6} md={3}>
              <label for="birthday">From:</label>
              <DatePicker
                selected={startDate}
                excludeDateIntervals={[
                  {
                    start: subDays(new Date("2021/02/08"), 0),
                    end: addDays(new Date("2022/01/15"), 0),
                  },
                ]}
                onChange={(date) => setStartDate(date)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <label for="birthday">To: </label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                className="mt-3"
                onClick={getDataForReport}
                variant="contained"
              >
                Get Here
              </Button>
              <Grid item xs={6} md={3}></Grid>
            </Grid>
          </Grid>

          {/* Table */}
          <br />
          <br />
          <Grid
            sx={{ display: "flex", justifyContent: "flex-start" }}
            className="mt-4 mb-4"
            conatainer
            spacing={4}
          >
            <Pdf targetRef={ref} filename="panic-report.pdf" options={options}>
              {({ toPdf }) => (
                <Button
                  variant="outlined"
                  onClick={() => {
                    toPdf();
                    waveHello();
                  }}
                >
                  Get Pdf
                </Button>
              )}
            </Pdf>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="btn btn-outline-primary p-2 mx-2"
              table="table-to-xls"
              filename="tablexls"
              sheet="tablexls"
              buttonText="Download as XLS"
            />
          </Grid>
          <div
            ref={ref}
            className="shadow-md p-2"
            style={{ borderRadius: "10px" }}
          >
            {showLogo ? (
              <div className="logo-container text-center">
                <img
                  className="logo-image"
                  src="/images/logocd.png"
                  alt="logo"
                />
              </div>
            ) : null}
            <table id="table-to-xls" class="download-table-xls-button table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Location</th>

                  {/* <th scope="col">IMEI</th> */}
                  <th scope="col"> Time of Call </th>
                  <th scope="col"> Duration(in sec) </th>
                  <th scope="col"> Mode</th>
                </tr>
              </thead>
              <tbody>{showDiv ? data.map(createTablerow) : null}</tbody>
            </table>

            {/* Graph */}
            <Grid container={2}>
              {showDiv ? (
                <Grid sx={{ padding: "1% 10%" }} container>
                  <div className="row">
                    <div className="col-lg-10 d-flex">
                      <img
                        style={{ width: "25%", height: "40px" }}
                        src="/images/report.png"
                        alt=""
                      />
                      <h4 className="fw-bold logs d-inline-block">
                        Analysis:-
                      </h4>
                    </div>
                  </div>

                  <Grid container item direction={"row"} xs={12}>
                    <Grid
                      className="shadow-md p-4 mt-4"
                      // sx={{ height: "75vh" }}
                      item
                      xs={12}
                      md={4}
                    >
                      <span className="mb-4">
                        {" "}
                        Range :- {res} to {rese}{" "}
                      </span>
                      <p>Location :- C-22-P </p>
                      <PieChart
                        total={numberOfCalls}
                        outgoing={numberOfCalls - incomingCalls}
                        incoming={incomingCalls}
                      />
                    </Grid>

                    <Grid
                      className="shadow-md p-4 mt-4 ff "
                      item
                      xs={12}
                      md={8}
                    >
                      <div className="shadows pad p-1 mb-5  rounded text-center col">
                        {/* <ul class="list-group">
                          <li class="list-group-item text-success">
                            <b>
                              Total Call Duration(min) :-{" "}
                              {Math.round((sum / 60 + Number.EPSILON) * 100) /
                                100}
                            </b>
                          </li>
                          <li class="list-group-item text-success">
                            <b>
                              Total Incoming Call Duration(min) :-{" "}
                              {Math.round(
                                (incomingDuration / 60 + Number.EPSILON) * 100
                              ) / 100}
                            </b>
                          </li>
                          <li class="list-group-item text-success">
                            <b>
                              Total Outgoing Call Duration(min) :-{" "}
                              {sum - incomingDuration}
                            </b>
                          </li>
                        </ul> */}
                        <TableContainer component={Paper}>
                          <Table
                            // sx={{ minWidth: 700 }}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Call&nbsp;</StyledTableCell>
                                <StyledTableCell align="right">
                                  Duration(min)&nbsp;
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <StyledTableRow key="call">
                                <StyledTableCell component="th" scope="row">
                                  Total Call Duration
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {sum > 60 &&
                                    Math.floor(sum / 60) +
                                      "min " +
                                      (sum % 60) +
                                      "sec"}
                                  {sum < 60 && sum + "sec"}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="incoming">
                                <StyledTableCell component="th" scope="row">
                                  Total Incoming Call Duration
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {incomingDuration > 60 &&
                                    Math.floor(incomingDuration / 60) +
                                      "min " +
                                      (incomingDuration % 60) +
                                      "sec"}
                                  {incomingDuration < 60 &&
                                    incomingDuration + "sec"}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="outgoing">
                                <StyledTableCell component="th" scope="row">
                                  Total Outgoing Call Duration
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {sum - incomingDuration > 60 &&
                                    Math.floor((sum - incomingDuration) / 60) +
                                      "min " +
                                      ((sum - incomingDuration) % 60) +
                                      "sec"}
                                  {sum - incomingDuration < 60 &&
                                    sum - incomingDuration + "sec"}
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                      {/* <IncomingVsOutgoing vf={nc} /> */}
                    </Grid>

                    {/* <Grid container item direction={"row"} xs={12}>
                      
                    </Grid> */}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </div>
        </Box>
      </Container>
    </div>
  );
};

export default Reports;
