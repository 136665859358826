import React, { Component } from "react";
import { Helmet } from "react-helmet";
import NewTable from "../../Components/Admin/NewTable";

class Table extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet titleTemplate="PRSS | %s" title="Table" />

        <NewTable />
      </React.Fragment>
    );
  }
}

export default Table;
