import React, { useState } from "react";
import AppContext from "./app-context";

function AppState(props) {
  const [auth, setAuth] = useState(false);
  const [callTime, setcallTime] = useState("");
  const [orgStartDate, setOrgStartDate] = useState("");
  const [ts, setTs] = useState("");
  const [orgEndDate, setOrgEndDate] = useState("");
  const [emergency, setEmergency] = useState(false);
  const [dateArray, setDateArray] = useState([]);
  const [nc, setNc] = useState([]);
  const [data, setData] = React.useState([])
  const [busStop, setBusStop] = React.useState([]);
  const [road, setRoad] = React.useState([]);
  const [wc, setWc] = React.useState([]);
  const [siteCode, setSiteCode] = useState([])
  const [sites, setSites] = useState("")
  const [addressData, setAddressData] = useState([]);
  const [sitess, setSitess] = useState("")
  const [coordinates, setCoordinates] = useState("");
  const [towards, setTowards] = React.useState([]);
  const [addresses, setAddresses] = React.useState([])
  const [numberOfEmergency, setNumberOfEmergency] = useState(0)
  const [numberOfFunctional, setNumberOfFunctional] = useState(0)
  const [numberOfNonFunctional, setNumberOfNonFunctional] = useState(0)
  const [progressValue, setProgressValue] = useState(0)
  const [age, setAge] = React.useState('');
  return (
    <AppContext.Provider
      value={{
        auth,
        setAuth,
        callTime,
        setcallTime,
        orgStartDate,
        orgEndDate,
        setOrgStartDate,
        setOrgEndDate,
        addresses, setAddresses,
        ts,
        age, setAge,
        setTs,
        emergency,
        setEmergency,
        dateArray, setDateArray, nc, setNc,data, setData, busStop, setBusStop,road, setRoad,siteCode,sites, setSites, setSiteCode,
        addressData, setAddressData,sitess, setSitess,coordinates, setCoordinates,towards, setTowards,
        numberOfEmergency, setNumberOfEmergency, numberOfFunctional, setNumberOfFunctional, numberOfNonFunctional, setNumberOfNonFunctional,
        message: "This is from the context", progressValue, setProgressValue
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}

export default AppState;
