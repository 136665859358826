import React, {useContext} from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import AppContext from "../../../context/app-context";


function TableRowe(props) {

  const {
    
  road,
    busStop, towards, siteCode
  } = useContext(AppContext);
  
  return <>
   <tr>
      <th scope="row">{props.in}</th>
      <td> {props.siteCode} </td>
      
      
      {/* <td>{props.imei}</td> */}
      <td>{props.timeOfCall}</td>
      <td>{props.call_time}</td>
      
      <td>{props.call_mode}</td>
    </tr>
  </>;
}

export default TableRowe;
