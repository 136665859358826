import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import NewModal from "./NewModal";
import BlockModal from "./BlockModal";
import BlockIdModal from "./BlockIdModal";
import PanchayatModal from "./PanchayatModal";
import PanchayatIdModal from "./PanchayatIDModal";
import WardModal from "./WardModal";
import WardIdModal from "./WardIdModal";
import Verification from "./Verification";

const url = process.env.REACT_APP_URL;

export default function NewTable() {
  const [deviceData, setDeviceData] = React.useState([]);
  
  async function fetchData() {
    try {
      const response = await axios.get('https://trackcoredata.com/wsms/field_visit/all_data');
      console.log(response.data.data);
      setDeviceData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    fetchData();
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "S.N",
      filterable: false,
      width: 60,
      renderCell: (index) => index.api.getRowIndex(index.row.unique_id) + 1,
    },
    {
      field: "ide",
      headerName: "S.Nd",
      filterable: false,
      width: 160,
      renderCell: (params) => {
        return <Verification image1={params.row.image_1} 
        image2 = {params.row.image_2}
        image3 = {params.row.image_3}
        image4 = {params.row.image_4}
        previsit = {params.row.pre_visit_remarks}
      
       dataforapi = {{
        "unique_id": params.row.unique_id,
        "image_1": params.row.image_1,
        "image_2": params.row.image_2,
        "image_3": params.row.image_3,
        "image_4": params.row.image_4,
        "pre_visit_remarks": params.row.pre_visit_remarks,
        "post_visit_remarks": params.row.post_visit_remarks,
       }}
        />;
      }
    },
    {
      field: "block",
      headerName: "Block",
      width: 140,
      editable: true,
      renderCell: (params) => {
        return <BlockModal block={params.row.block} />;
      },
    },
    
    {
      field: "panchayat",
      headerName: "Panchayat",
      width: 140,
      editable: true,
      renderCell: (params) => {
        return <PanchayatModal block={params.row.panchayat} />;
      },
    },
    
    {
      field: "ward",
      headerName: "Ward",
      width: 140,
      editable: true,
      renderCell: (params) => {
        return <WardModal block={params.row.ward} />;
      },
    },
    {
      field: "device_id",
      headerName: "Device ID",
      filterable: false,
      editable: true,
      width: 100,
    },
    {
      field: "ts",
      headerName: "Time of visit",
      width: 200,
      editable: true,
    },
    {
      field: "field_user",
      headerName: "Field User",
      width: 200,
      editable: true,
    },
    {
      field: "verified",
      headerName: "Verified",
      width: 80,
      editable: true,
    },
    {
      field: "image_1_label",
      headerName: "Image 1",
      width: 120,
      editable: true,
      renderCell: (params) => {
        return (
          <NewModal
            image={params.row.image_1}
            label={params.row.image_1_label}
            id={params.row.unique_id}
          />
        );
      },
    },
    {
      field: "image_2_label",
      headerName: "Image 2",
      width: 100,
      editable: true,
      renderCell: (params) => {
        return (
          <NewModal
            image={params.row.image_2}
            label="Image 2"
            id={params.row.unique_id}
          />
        );
      },
    },
    {
      field: "image_3_label",
      headerName: "Image 3",
      width: 100,
      editable: true,
      renderCell: (params) => {
        return (
          <NewModal
            image={params.row.image_3}
            label="Image 3"
            id={params.row.unique_id}
          />
        );
      },
    },
    {
      field: "image_4_label",
      headerName: "Image 4",
      width: 100,
      editable: true,
      renderCell: (params) => {
        return (
          <NewModal
            image={params.row.image_4}
            label="Image 4"
            id={params.row.unique_id}
          />
        );
      },
    },
   
    {
      field: "address",
      headerName: "Address",
      width: 200,
      editable: true,
    },
    {
      field: "pre_visit_remarks",
      headerName: "Pre Visit Remarks",
      width: 100,
      editable: true,
    },
    {
      field: "post_visit_remarks",
      headerName: "Post Visit Remarks",
      width: 100,
      editable: true,
    },
    {
      field: "distance_difference",
      headerName: "Distance Difference",
      width: 100,
      editable: true,
    }
  ];

  return (
    <div style={{ height: 450, width: "100%", marginTop: "30px", padding:"2%" }}>
      <div className="header">
        <div class="text-center mb-3 mt-4 three">
          <h1 className="d-inline-block">Field Visit Data</h1>
        </div>
      </div>
      <DataGrid
        align="center"
        rows={deviceData}
        getRowId={(row) => row.unique_id}
        columns={columns}
        pageSize={15}
        rowsPerPageOptions={[15]}
        disableSelectionOnClick
      />
    </div>
  );
}
