import React, { useState, useEffect } from "react";
import EarthingMap from "./EarthingMap";
import * as mqtt from "mqtt";
import CheckboxesTags from "../Reports/SearchSelect";
import Road from "../Reports/Road";
import Towards from "../Reports/Towards";
import MultipleSelectCheckmarks from "../Reports/MultipleSelectCheckmarks";
import fs from "fs";
import ReactWhatsapp from "react-whatsapp";
import Select from "./Select";
import Switchs from "./Switchs";

import Weightometer from "../Weight/Weightometer";
import axios from "axios";

function Earthing() {
  const [message, setMessage] = useState("");
  const [earthingState, setEarthingState] = useState(false);
  const [data, setData] = useState({});
  const [showImage1, setShowImage1] = useState(false);
  const [showImage2, setShowImage2] = useState(true);
  const [earthingStatus, setEarthingStatus] = useState(false);
  const [deviceConnection, setDeviceConnection] = useState(false);
  const [deviceHealth, setDeviceHealth] = useState(false);
  const [lastUpdated, setLastUpdated] = useState("");

  // useEffect(()=>{
  // axios.get("http://localhost:5000/latest_weight").then((response)=>{
  //   console.log(response.data.data.weight);
  //   setData(response.data.data)
  //   console.log(parseInt(response.data.data.weight));
  //   setMessage(parseFloat(response.data.data.weight))
  // })
  //   },[message, data])

  useEffect(() => {

axios.get("https://trackcoredata.com/earthing/data/get").then((response)=>{
  
const date = new Date(response.data.data.ts);
const options = { timeZone: "Asia/Kolkata" };
const istTime = date.toLocaleString("en-US", options);

var date1 = new Date(response.data.data.ts.slice(0, 19));
    var ws = date1.toString();
    var ds = ws.slice(0, 24);

setLastUpdated(istTime)

})


    const clientId = "mqttjs_" + Math.random().toString(16).substr(2, 8);

    const host = "mqtt://mqtt.trackcoredata.com:9001/mqtt";

    const options = {
      keepalive: 60,
      clientId: clientId,
      protocolId: "MQTT",
      protocolVersion: 4,
      clean: true,
      reconnectPeriod: 1000,
      connectTimeout: 30 * 1000,

      will: {
        topic: "WillMsg",
        payload: "Connection Closed abnormally..!",
        qos: 0,
        retain: false,
      },
    };

    console.log("Connecting mqtt client");
    const client = mqtt.connect(host, options);

    client.on("error", (err) => {
      console.log("Connection error: ", err);
      client.end();
    });

    client.on("reconnect", () => {
      console.log("Reconnecting...");
    });

    client.on("connect", function () {
      setDeviceConnection(true);
      client.subscribe("earth/866557054012093/telemetry", function (err) {
        if (!err) {
          console.log("hi");
        }
      });
    });

    client.on("message", function (topic, message) {

      const now = new Date();
const currentDate = now.toLocaleDateString();
const currentTime = now.toLocaleTimeString();
const req = currentDate + " " + currentTime;
setLastUpdated(req)


      console.log("1", message);
      console.log("type", typeof message);
      console.log("2", message.toString());
      console.log("typer", typeof message.toString());

      setMessage(message.toString());

      var parsedJson = JSON.parse(message.toString());
      console.log("parsedJson", parsedJson);
      console.log("parsedJson.earthing_status", parsedJson.earthing_status);
      if (parsedJson.earthing_status == 1) {
        console.log("Hi I am 1");
        setEarthingState(true);
        setShowImage1(true);
        setShowImage2(false);
      } else {
        console.log("Hi I am 0");
        setEarthingState(false);
        setShowImage1(false);
        setShowImage2(true);
        const apiCall = async () => {
          const response = await axios.post("https://trackcoredata.com/call/alert");
          console.log(response);
          // alert(response.data.message);
        };
        apiCall();
      }

      //   client.end();
    });
  }, [message, data, earthingState]);

  return (
    <>
      <div className="row p-2">
        <div className="col">
          <div className="row">
           
            <div className="col mt-2">
            {/* <Select /> */}
            </div>
            <div className="col ">
              {/* <MultipleSelectCheckmarks /> */}
            </div>
            <div className="col mx-4">
              {/* <Road /> */}
            </div>
            <div className="col">
              {/* <Towards /> */}
            </div>
            {/* <div className="col-lg-2">
                <p>Alert</p>
                
            </div> */}
            <div className="col">
              <div className="row">
              <Switchs />
              </div>
              <div className="row">
                <span> <b> Alert</b> </span>
                </div>
             
            </div>
            <div className="row">
              <EarthingMap />
            </div>
          </div>
          {/* <h1>
            {" "}
            <b>Earthing Status :- </b>{" "}
            {showImage1 ? "Connected" : "Not Connected"}{" "}
          </h1> */}
        </div>
        <div className="col-lg-5">
          <div className="row mt-4 p-2 m-auto text-center">
            <div className="col">
            {showImage1 && (
              <img
                className="img-responsive earth"
                src="/images/nat1.png"
                alt=""
              />
            )}
            {showImage2 && (
              <img
                className="img-responsive earth"
                src="/images/nat2.png"
                alt=""
              />
            )}
            </div>
          </div>
          <div className="row  p-2 mt-2">
            <div className="col-lg-12">
              <ul style={{width:"80%"}}  class=" m-auto list-group list-group-flush shadow-md">
              <li class="list-group-item">Device connection : <b  className={deviceConnection ? "highlighted": "not-highlighted"}> { deviceConnection ? "Online":"Offline" } </b> </li>
                <li class={`list-group-item`}>Earthing status : <b className={showImage1 ? "highlighted": "not-highlighted"}> {showImage1? "Connected" : "Not Connected" } </b>  </li>
               
                <li class="list-group-item">Device health : <b className="highlighted" >Healthy</b> </li>
                <li class="list-group-item">Last updated : <b>{lastUpdated} </b> </li>
                <li class="list-group-item">Data frequency : <b>5s</b> </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Earthing;
