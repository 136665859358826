import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AppContext from "../../../context/app-context";
import { useEffect, useContext, useState } from "react";
import { useStateManager } from "react-select";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags() {
  const { setData, data,addresses, siteCode,setSites, setSiteCode } = useContext(AppContext);
  const top100Films = addresses;

 const fixedOptions = [top100Films[0]];
  const [value, setValue] = React.useState([]);


//   const [name, setName] = useState([]);
//   const handleChange = (e) => {
//       console.log("hi");
//     let data = name;
//     data.push(e.target.value);
//     console.log(data);
//     setName(data);
//   };
//   console.log(name[0]);

var z =[]
const f = value.map((v)=>{
z.push(v.site_code)

})

var g = z.toString()
console.log(g);
console.log(typeof g);
setSites(g)

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={top100Films}
      disableCloseOnSelect
      onChange={(event, newValue) => {
        setValue([
          
          ...newValue,
        ]);
      }}
      getOptionLabel={(option) => option.site_code}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 2 }}
            checked={selected}
          />
          {option.site_code}
        </li>
      )}
      style={{ width: 120 }}
      renderInput={(params) => (
        <TextField {...params} label="Site Code" placeholder="Select More" />
      )}
    />
  );
}
