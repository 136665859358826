import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Button from "@mui/material/Button";
import BasicModal from "./BasicModal";
import { useState } from "react";
import DeleteModal from "./DeleteModal";
import ReplacementModal from "./ReplacementModal";
import UploadImageModal from "./UploadImageModal";


const url = process.env.REACT_APP_URL


export default function RegisteredBoxTable() {
  const [deviceData, setDeviceData] = React.useState([]);
  const [preInstallationImageOne, setPreInstallationImageOne] = useState("");
  const [preInstallationImageTwo, setPreInstallationImageTwo] = useState("");
  const [postInstallationImageOne, setPostInstallationImageOne] = useState("");
  const [postInstallationImageTwo, setPostInstallationImageTwo] = useState("");

  React.useEffect(() => {
    axios
      .get(

        `${url}/core_data/all/site/installed/box`

      )
      .then((response) => {
        console.log(response.data.data);
        setDeviceData(response.data.data);
        console.log("hkuh", response.data.data[0].id);
        setPreInstallationImageOne(
          response.data.data.drill_hole_image
        );
        setPreInstallationImageTwo(
          response.data.data.rabbit_nut_image
        );
        setPostInstallationImageOne(
          response.data.data.final_image_1
        );
        setPostInstallationImageTwo(
          response.data.data.final_image_2
        );
      });
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "S.No.",
      filterable: false,
      width: 60,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "site_code",
      headerName: "Site Code",
      width: 90,
      editable: true,
    },
    {
      field: "box_id",
      headerName: "Box Number",
      width: 150,
      editable: true,
    },
    
    {
      field: "ts",
      headerName: "Installation Date",
      width: 150,
      editable: true,
    },
    {
      field: "person_installed",
      headerName: "Person Installed",
      width: 150,
      editable: true,
    },
    {
        field: "remarks",
        headerName: "Remarks",
        width: 150,
        editable: true,
      },

    {
      field: "post_installation_image_url_1",
      headerName: "Images",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return (
          <BasicModal
            yu={params.row.site_code}
            url1={params.row.drill_hole_image}
            url2={params.row.rabbit_nut_image}
            url3={params.row.final_image_1}
            url4={params.row.final_image_2}
          />
        );
      },
    },
    {
      field: "post_installation_image_url",
      headerName: "Images",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return (
          <UploadImageModal
            siteCode = {params.row.site_code}
            
          />
        );
      },
    }
    // {
    //   field: "remarks",
    //   headerName: "Delete",
    //   width: 110,
    //   editable: true,
    //   renderCell: (params) => {
    //     return <DeleteModal siteCode={params.row.site_code} />;
    //   },
    // },
    // {
    //   field: "remark",
    //   headerName: "Replace",
    //   width: 110,
    //   editable: true,
    //   renderCell: (params) => {
    //     return <ReplacementModal siteCode={params.row.site_code} />;
    //   },
    // },
  ];

  return (
    <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
      <DataGrid
        align="center"
        rows={deviceData}
        columns={columns}
        pageSize={15}
        rowsPerPageOptions={[15]}
        disableSelectionOnClick
      />
    </div>
  );
}
