import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  import faker from 'faker';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' 
      },
      title: {
        display: true,
        text: 'Total Number of Calls in a Day',
      },
    },
  };
  
  const labels = ['11-02-2022', '12-02-2022', '13-02-2022', '14-02-2022', '15-02-2022', '16-02-2022', '17-02-2022'];
  
  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 20 })),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
     
    ],
  };

function Cardline() {
  return  <Line options={options} data={data} />;
}

export default Cardline





