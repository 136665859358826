import React, { useEffect, useState } from "react";
import * as mqtt from "mqtt";
import VerticalSlider from "./VerticalSlider";
import Weightometer from "../Weight/Weightometer";
import axios from "axios";

function MqttOverWebsocketConnection() {
  const [message, 
    setMessage] = useState(0);
  const [data, setData] = useState("")
  

  // useEffect(()=>{
// axios.get("http://localhost:5000/latest_weight").then((response)=>{
//   console.log(response.data.data.weight);
//   setData(response.data.data)
//   console.log(parseInt(response.data.data.weight));
//   setMessage(parseFloat(response.data.data.weight))
// })
//   },[message, data])

  const clientId = "mqttjs_" + Math.random().toString(16).substr(2, 8);

  const host = "ws://mqtt.trackcoredata.com:9001/mqtt";

  const options = {
    keepalive: 60,
    clientId: clientId,
    protocolId: "MQTT",
    protocolVersion: 4,
    clean: true,
    reconnectPeriod: 1000,
    connectTimeout: 30 * 1000,
    will: {
      topic: "WillMsg",
      payload: "Connection Closed abnormally..!",
      qos: 0,
      retain: false,
    },
  };

  console.log("Connecting mqtt client");
  const client = mqtt.connect(host, options);

  client.on("error", (err) => {
    console.log("Connection error: ", err);
    client.end();
  });

  client.on("reconnect", () => {
    console.log("Reconnecting...");
  });

  client.on("connect", function () {
    client.subscribe("weight", function (err) {
      if (!err) {
        console.log("hi");
      }
    });
  });

  client.on("message", function (topic, message) {
    // message is Buffer
    console.log(message.toString());
    setMessage(message.toString());
    client.end();
  });
  // Publish

  return (
    <div>
      <div className="row p-2 mt-1  shadow-md">
        <div style={{"marginLeft":"300px"}} className="col  float-start">
          
          <Weightometer weight={message} />
         
        </div>
        <div  className="col-md-6 mt-3">
        <p> <b> Exact Weight of the Grain :- {message}</b>  </p>
          <p> <b>Expected Weight of the Grain :- 100 kg</b>  </p>
          <p> <b>Defficiency of the Grain :- 2 kg</b>  </p>
          <p> <b> Date and Time  :- 20 Feb 2023 11:15 a.m.</b>  </p>
        </div>
      </div>
    </div>
  );
}

export default MqttOverWebsocketConnection;
