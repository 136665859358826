import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import axios from "axios";

const url = process.env.REACT_APP_URL;
export default function NewButton() {
  const [basicModal, setBasicModal] = useState(false);

  const toggleShow = () => setBasicModal(!basicModal);
  //   const submitForm = (event) => {
  //     event.preventDefault();
  //     console.log(event.target[0].value);
  //   };
  return (
    <>
      <MDBBtn onClick={toggleShow} className="btn btn-default btn-rounded mb-4">
        New User
      </MDBBtn>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="text-center">
                Add new user
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                const URL = `${url}/api/sign_up`;
                const data = {
                  email: event.target[0].value,
                  email_verified: "False",
                  password: event.target[1].value,
                  display_name: event.target[2].value,
                  phone_number: event.target[3].value,
                  disabled: event.target[4].value,
                  photo_url: event.target[5].value,
                  role: event.target[6].value,
                };
                console.log("hudskhksh", data);
                axios.post(URL, data).then((response) => {
                             console.log(response.data); 
                             alert(response.data.message)    
                  
                });
              }}
            >
              <MDBModalBody>
                <div class="modal-body mx-3">
                  <div class="md-form mb-4 d-flex">
                    <i
                      class="fas fa-envelope prefix grey-text"
                      style={{ margin: "auto 10px", width: "20px" }}
                    ></i>
                    <input
                      type="email"
                      id="defaultForm-email"
                      class="form-control validate"
                      placeholder="Email"
                      style={{
                        borderRight: "0px",
                        borderTop: "0px",
                        borderLeft: "0px",
                        boxShadow: "none",
                      }}
                    />
                  </div>

                  <div class="md-form mb-4 d-flex">
                    <i
                      class="fas fa-lock prefix grey-text"
                      style={{ margin: "auto 10px ", width: "20px" }}
                    ></i>
                    <input
                      type="password"
                      id="defaultForm-pass"
                      class="form-control validate"
                      placeholder="Password"
                      style={{
                        borderRight: "0px",
                        borderTop: "0px",
                        borderLeft: "0px",
                        boxShadow: "none",
                      }}
                    />
                  </div>

                  <div class="md-form mb-4 d-flex">
                    <i
                      class="fas fa-file-signature prefix grey-text"
                      style={{ margin: "auto 10px ", width: "20px" }}
                    ></i>
                    <input
                      type="text"
                      id="defaultForm-name"
                      class="form-control validate"
                      placeholder="Display Name"
                      style={{
                        borderRight: "0px",
                        borderTop: "0px",
                        borderLeft: "0px",
                        boxShadow: "none",
                      }}
                    />
                  </div>

                  <div class="md-form mb-4 d-flex">
                    <i
                      class="fas fa-mobile prefix grey-text"
                      style={{ margin: "auto 10px ", width: "20px" }}
                    ></i>
                    <input
                      type="text"
                      id="defaultForm-number"
                      class="form-control"
                      placeholder="Mobile number"
                      style={{
                        borderRight: "0px",
                        borderTop: "0px",
                        borderLeft: "0px",
                        boxShadow: "none",
                      }}
                    />
                  </div>

                  <div class="md-form mb-4 d-flex">
                    <i
                      class="fas fa-universal-access prefix grey-text"
                      style={{ margin: "auto 10px ", width: "20px" }}
                    ></i>
                    <input
                      type="text"
                      id="defaultForm-disabled"
                      class="form-control validate"
                      placeholder="Disabled(True/False)"
                      style={{
                        borderRight: "0px",
                        borderTop: "0px",
                        borderLeft: "0px",
                        boxShadow: "none",
                      }}
                    />
                  </div>

                  <div class="md-form mb-4 d-flex">
                    <i
                      class="fas fa-file-image prefix grey-text"
                      style={{ margin: "auto 10px ", width: "20px" }}
                    ></i>
                    <input
                      type="text"
                      id="defaultForm-photo"
                      class="form-control validate"
                      placeholder="Photo URL"
                      style={{
                        borderRight: "0px",
                        borderTop: "0px",
                        borderLeft: "0px",
                        boxShadow: "none",
                      }}
                    />
                  </div>

                  <div class="md-form mb-4 d-flex">
                    <i
                      class="fas fa-bolt prefix grey-text"
                      style={{ margin: "auto 10px ", width: "20px" }}
                    ></i>
                    <input
                      type="text"
                      id="defaultForm-role"
                      class="form-control validate"
                      placeholder="Role"
                      style={{
                        borderRight: "0px",
                        borderTop: "0px",
                        borderLeft: "0px",
                        boxShadow: "none",
                      }}
                    />
                  </div>
                </div>
              </MDBModalBody>

              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleShow}>
                  Close
                </MDBBtn>
                <MDBBtn type="submit">Create User</MDBBtn>
              </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
