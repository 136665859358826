import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Button from "@mui/material/Button";
import BasicModal from "./BasicModal";
import { useState } from "react";
import VolumeModal from "./VolumeModal";
import LimitModal from "./LimitModal";
import ATModal from "./ATModal";
import RebootModal from "./RebootModal";
import DeviceStatus from "./DeviceStatus";
import ProgressBar from "./ProgressBar";
import { useContext } from "react";
import AppContext from "../../../context/app-context";

const url = process.env.REACT_APP_URL;

export default function ButtonsTable() {
  const { progressValue, setProgressValue } = useContext(AppContext);
  const [deviceData, setDeviceData] = React.useState([]);

  React.useEffect(() => {
    axios.get(`${url}/core_data/all/devices`).then((response) => {
      console.log(response.data.data);
      setDeviceData(response.data.data);
    });
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "S.No.",
      filterable: false,
      width: 60,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "site_code",
      headerName: "Site Code",
      width: 140,
      editable: true,
    },
    {
      field: "imei",
      headerName: "IMEI Number",
      width: 200,
      editable: true,
    },
    
    {
      field: "volume",
      headerName: "Volume",
      width: 140,
      editable: true,
      renderCell: (params) => {
        return (
          <VolumeModal
            imei={params.row.imei}
            personInstalled={params.row.person_installed}
            siteCode={params.row.site_code}
          />
        );
      },
    },
    {
      field: "limit",
      headerName: "Limit",
      width: 160,
      editable: true,
      renderCell: (params) => {
        return (
          <LimitModal
            imei={params.row.imei}
            personInstalled={params.row.person_installed}
            siteCode={params.row.site_code}
          />
        );
      },
    },
    {
      field: "at",
      headerName: "AT",
      width: 160,
      editable: true,
      renderCell: (params) => {
        return (
          <ATModal
            imei={params.row.imei}
            personInstalled={params.row.person_installed}
            siteCode={params.row.site_code}
          />
        );
      },
    },
    {
      field: "reboot",
      headerName: "Reboot",
      width: 140,
      editable: true,
      renderCell: (params) => {
        return (
          <RebootModal
            imei={params.row.imei}
            personInstalled={params.row.person_installed}
            siteCode={params.row.site_code}
          />
        );
      },
    },
    {
      field: "Device Status",
      headerName: "Device Status",
      width: 140,
      editable: true,
      renderCell: (params) => {
        return (
          <DeviceStatus
            
            site_code={params.row.site_code}
          />
        );
      },
    },
  ];

  return (
    <div style={{ height: 450, width: "100%", marginTop: "700px" }}>
      <div className="header">
        <div class="text-center mb-3 mt-4 three">
          <h1 className="d-inline-block">Set Commands</h1>
          <ProgressBar progress = {progressValue} />
        </div>
      </div>
      <DataGrid
        align="center"
        rows={deviceData}
        columns={columns}
        pageSize={15}
        rowsPerPageOptions={[15]}
        disableSelectionOnClick
      />
    </div>
  );
}
