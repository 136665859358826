import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import AppContext from "../../../context/app-context"; 
import { useContext } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};



export default function Towards() {
    const { setData, data,road,busStop,towards, setTowards,addresses,  setRoad} =
    useContext(AppContext);
  

  const names = addresses;
  const newArray1 = names.filter((single) => {
    return (
      single.bus_stop_name === busStop[0] ||
      single.bus_stop_name === busStop[1] ||
      single.bus_stop_name === busStop[2] ||
      single.bus_stop_name === busStop[3]
    );
  });
const towardsArray = newArray1.filter((single) => {
  return (
    
    single.road_name === road[0] ||
    single.road_name === road[1] ||
    single.road_name === road[2] ||
    single.road_name === road[3]
  );
});
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setTowards(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 120 }}>
        <InputLabel id="demo-multiple-checkbox-label">Towards</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={towards}
          onChange={handleChange}
          input={<OutlinedInput label="Bus Stop" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {towardsArray.map((name) => (
            <MenuItem key={name.id} value={name.towards}>
              <Checkbox checked={towards.indexOf(name.towards) > -1} />
              <ListItemText primary={name.towards} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
