import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { number } from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#9CA2B2",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function DeviceStatus(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [getRemarks, setGetRemarks] = useState("");
  const [imei, setImei] = useState("");
  const [latestActiveTime, setLatestActiveTime] = useState("");
  const [version, setVersion] = useState("");
  const [volume, setVolume] = useState("");
  const [buttonState, setButtonState] = useState("");
  const [lp, setLP] = useState("");
  const [tp, setTp] = useState("");
  const [simNumber, setSimNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const url = process.env.REACT_APP_URL;

  const URL = `${url}/api/v1/device/status?site=${props.site_code}`;

  const getDeviceStatus = () => {
    axios
      .post(URL)
      .then((response) => {
        console.log(response);
        console.log(response.status);
        // console.log("imei", response.data.data[0].imei);
        //   setImei(response.data.data[0].imei)
        const dataArray = response.data.data
        if (typeof dataArray !== 'undefined' && dataArray.length > 0) {
            // the array is defined and has at least one element
            setImei(response.data.data[0].imei)
            setPhoneNumber(response.data.data[0].number)
          setLP(response.data.data[0].lp)
          setTp(response.data.data[0].tp)
          setVolume(response.data.data[0].volume_range)
          setButtonState(response.data.data[0].button_state)
          setSimNumber(response.data.data[0].sm)
          setVersion(response.data.data[0].version)
          setLatestActiveTime(response.data.data[0].ts)
        }
          
      })
      .then(() => {
        console.log("check");
      });
  };

  return (
    <div>
      <Button
        sx={{ textTransform: "none" }}
        onClick={() => {
          handleOpen();
          getDeviceStatus();
        }}
      >
        Check Device Status
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="header text-center">
            <div class="text-center mb-3 three">
              <h1 className="d-inline-block">Device Status</h1>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Parameter&nbsp;</StyledTableCell>
                  <StyledTableCell align="right">Value</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow key="call">
                  <StyledTableCell component="th" scope="row">
                    IMEI
                  </StyledTableCell>
                  <StyledTableCell align="right">{imei}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow key="incoming">
                  <StyledTableCell component="th" scope="row">
                    Latest Active Time
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {latestActiveTime}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow key="outgoing">
                  <StyledTableCell component="th" scope="row">
                    Version
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {version}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow key="outgoing">
                  <StyledTableCell component="th" scope="row">
                    Volume
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {volume}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow key="outgoing">
                  <StyledTableCell component="th" scope="row">
                    Button State
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {buttonState}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow key="outgoing">
                  <StyledTableCell component="th" scope="row">
                    Tamper Status
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {lp} , {tp} {lp===1? "box open":"box closed"}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow key="outgoing">
                  <StyledTableCell component="th" scope="row">
                    Sim Number
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {simNumber}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow key="outgoing">
                  <StyledTableCell component="th" scope="row">
                    Configured Phone Number
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {phoneNumber}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}

export default DeviceStatus;
