import React, { Component, useContext } from "react";

import PrimarySearchAppBar from "../../Components/Layout/Header";
import { Grid } from "@mui/material";

import Map from "../../Components/Home/MapDriver";
import CardSection from "../../Components/Home/CardSection";

import Footer from "../../Components/Home/Footer";

import ModalReport from "../../Components/Home/ModalReport";
import DataTable from "../../Components/Home/FilterTable";

import TopSection from "../../Components/Home/TopSection";

import Doughnut from "../../Components/Home/Doughnut";
import Socket from "../../Components/Home/Socket";
import AppContext from "../../../context/app-context";
import SingleLineChart from "../../Components/Home/SingleLineChart";
import Doughnut1 from "../../Components/Home/Doughnut";
import UnstyledTable from "../../Components/Home/Emergency";
import MultipleSelect from "../../Components/Home/Select";
import CallsTrend from "../../Components/Home/CallsTrend";
import Users from "../../Components/Home/Users";
import ReactMap from "../../Components/Home/ReactMap";
import MqttOverWebsocketConnection from "../../Components/Home/MqttOverWebsocketConnection";
import CallsTrendHighcharts from "../../Components/Home/CallsTrendHighcharts";
import BeaconGraphChartWithData from "../../Components/Home/BeaconGraphChartWithData";
import BeaconGraph from "../../Components/Home/BeaconGraph";

function Index() {
  const { auth } = useContext(AppContext);
  return (
    <div>
      <PrimarySearchAppBar />
      <Grid sx={{ marginTop: "0.3rem" }} container>
        <Grid container item direction={"row"} xs={12}>
          <Grid sx={{ height: "50vh" }} item xs={12} md={2.5}>
            <Doughnut />
          </Grid>

          <Grid
            sx={{ padding: "0% 2%", padding: "5% 2%" }}
            item
            xs={12}
            md={9.5}
          >
            <TopSection />
          </Grid>
        </Grid>

        <Grid container item direction={"row"} xs={12}>
          <Grid item xs={12} md={6}>
            <ReactMap />
          </Grid>

          <Grid sx={{ padding: "0% 2%" }} item xs={12} md={6}>
            <CardSection />
          </Grid>
        </Grid>
        <Grid container item direction={"row"} xs={12}>
          <Grid sx={{ padding: "2%" }} item xs={12} md={8}>
            <div class="text-center mb-3 three">
              <h1 className="d-inline-block">Emergency Sites :-</h1>
            </div>

            <div className="shadow-md fix-width">
              <UnstyledTable />
            </div>
          </Grid>
          <Grid
            sx={{
              padding: "2%",
              textAlign: "center",
            }}
            item
            xs={12}
            md={4}
          >
            {/* <div className="three">
              <h1 className="mb-4  d-inline-block">Calls Trend :-</h1>
            </div>

            <div style={{ height: "400px" }} className="shadow-md ">
              <CallsTrendHighcharts />
            </div> */}
            {/* <Users /> */}
          </Grid>
        </Grid>
        <Grid container item direction={"row"} xs={12}>
          <Grid sx={{ marginTop: "3rem" }} item xs={12}>
            <DataTable />
          </Grid>
        </Grid>
      </Grid>
      {/* <ReactMap/> */}
      {/* <Socket /> */}

      {/* <BeaconGraph /> */}
      {/* <MqttOverWebsocketConnection /> */}
      <Footer />
    </div>
  );
}
export default Index;
