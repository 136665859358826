import React, { useState, useEffect, useContext } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "./Map.css";
import * as parkData from "./locations.json";
import { Icon } from "leaflet";
import axios from "axios";
import AppContext from "../../../context/app-context";
import moment from "moment";

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

const redIcon = new Icon({
    iconUrl: "/red.png",
    iconSize: [30, 30]
})

const greenIcon = new Icon({
    iconUrl: "/orange.png",
    iconSize: [30, 30]
})

const orangeIcon = new Icon({
    iconUrl: "/green.png",
    iconSize: [30, 30]
})

function MapDriver(props) {
    const { ts, setTs, emergency
    } = useContext(AppContext);
    const [recent_call, setcallTime] = useState(false);

     
    
//  const x = Math.floor((d- date1)/60000)
//  console.log(typeof x);
   

    //  console.log(date1);
    //  console.log(now - newTs);
     
    //  console.log(now);
    //  console.log(moment().format('LTS'));
    //  console.log(newTs);
    useEffect(() => {

        
    
  
        
        // axios.get("https://cors-everywhere.herokuapp.com/http://3.110.221.53:2201/api/v1/get_data").then((response) => {
          
          
          
        //       var call_time = response.data.data.values.callTime
              
            
             
        //    });
      }, []);
    return (
        <MapContainer center={[28.628524, 77.223274]} zoom={12}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {parkData.features.map((park) => (
                <Marker
                    key={park.properties.PARK_ID}
                    position={park.geometry.coordinates}
                    icon={park.type === "Red" & emergency ? redIcon : park.type==="Orange" ? orangeIcon: greenIcon }
                >
                <Popup
                     position={park.geometry.coordinates}
                >
                    <div>
                        <h6>{park.properties.NAME} </h6>
                        <p>{park.properties.DESCRIPTIO}</p>
                    </div>
                </Popup>
            </Marker>
             ))}
        </MapContainer>
    );
}

export default MapDriver;
