import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@material-ui/core";
import { useState, useEffect } from "react";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function NewModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [newVersion, setNewVersion] = useState("");
  const [previousVersion, setPreviousVersion] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [getRemarks, setGetRemarks] = useState("");

  return (
    <div>
      <Button sx={{ textTransform: "none" }} onClick={handleOpen}>
        {props.label}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="header text-center">
            <div class="text-center mb-3 three">
              {props.image && (
                <img src={props.image} style={{ width: "50%" }} />
              )}
            </div>
          </div>
          <div className="d-flex">
            <Button
              color="warning"
              variant="contained"
              className="d-block m-auto"
             
            >
              Delete Image
            </Button>
            <Button
              color="success"
              variant="contained"
              className="d-block m-auto"
            >
              Upload Image
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
