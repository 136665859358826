import React, { useEffect, useState, useContext } from "react";
import SelectOption from "./SelectOption";
import top100Films from "./top100films";
import axios from "axios";
import AppContext from "../../../context/app-context";
import SiteCode from "../Reports/SiteCode";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SingleLineChart from "./SingleLineChart";
import { Chart } from "react-google-charts";
import Cardline from "./Cardline";
import { aws4Interceptor } from "aws4-axios";
import { LogarithmicScale } from "chart.js";
import MultipleSelectCheckmarks from "../Reports/MultipleSelectCheckmarks";
import Road from "../Reports/Road";
import Towards from "../Reports/Towards";
import "dotenv/config";
import Example from "../Device/CallReportChart";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useAuth } from "../../../context/AuthContext";
import { getAuth } from "firebase/auth";

const url = process.env.REACT_APP_URL;

function CardSection() {
  const auth = getAuth();
  const {
    message,
    callTime,
    setcallTime,
    road,
    towards,
    busStop,
    ts,
    setTs,
    setEmergency,
  } = useContext(AppContext);

  const [callMode, setCallMode] = useState("");
  const [noOfInstalledDevices, setNoOfInstalledDevices] = useState("");
  const [data, setData] = useState([]);
  const [district, setDistrict] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [value, setValue] = React.useState("Lodhi Colony");
  const [duration, setDuration] = React.useState(20);
  const [dataOfNumberOfCalls, setDataOfNumberOfCalls] = useState([]);
  const { currentUserLogged } = useAuth();

  const handleChange = (event) => {
    setDuration(event.target.value);
  };
  const siteCodes = [{ value: "chocolate", label: "C-22-P" }];
  const busStops = [{ value: "chocolate", label: "Palika Kendra" }];
  const roads = [{ value: "chocolate", label: "Sansad Marg" }];
  const towardss = [{ value: "chocolate", label: "Parliament House" }];
  const handleChangedistrict = (event) => {
    setDistrict(event.target.value);
  };

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };

  useEffect(() => {
    const user = auth.currentUser;
    // console.log(user.email);
    if (duration === 10) {
      const d = new Date();
      const today = d.toISOString().split("T")[0];
      const d2 = new Date();
      const sevenDaysBefore = d2.setDate(d.getDate() + 1);
      const g = new Date(sevenDaysBefore);
      var tomorrow = g.toISOString().split("T")[0];

      axios
        .get(
          `${url}/api/v1/number_of_calls?startOffset=${today}&endOffset=${tomorrow}`
        )
        .then((response) => {
          setDataOfNumberOfCalls(response.data.data);
        });
    } else if (duration === 20) {
      const d = new Date();
      const h = d.toISOString().split("T")[0];
      const sevenDaysBefore = d.setDate(d.getDate() - 7);
      const g = new Date(sevenDaysBefore);
      var t = g.toISOString().split("T")[0];

      axios
        .get(`${url}/api/v1/number_of_calls?startOffset=${t}&endOffset=${h}`)
        .then((response) => {
          setDataOfNumberOfCalls(response.data.data);
        });
    } else if (duration === 30) {
      const d = new Date();
      var t = d.toISOString().split("T")[0];
      axios
        .get(
          `${url}/api/v1/number_of_calls?startOffset=2022-08-01&endOffset=${t}`
        )
        .then((response) => {
          setDataOfNumberOfCalls(response.data.data);
        });
    }

    // let one = `${url}/api/v1/get_data`;

    // const requestOne = axios.get(one);

    // axios
    //   .all([requestOne])
    //   .then(
    //     axios.spread((...responses) => {
    //       const responseOne = responses[0];

    //       setcallTime(responseOne.data.data.call_time);
    //       setCallMode(responseOne.data.data.call_mode);
    //       setData(responseOne.data.data);
    //       setTs(responseOne.data.data.ts);

    //       var date1 = new Date(responseOne.data.data.ts.slice(0, 19) + " UTC");
    //       var ws = date1.toString();
    //       var ds = ws.slice(0, 24);
    //       console.log(ds);
    //       console.log(ws);
    //       console.log(date1);
    //       var now = new Date();
    //       console.log(now);
    //       console.log(Math.floor((now - date1) / 60000));
    //       if (Math.floor((now - date1) / 60000) <= 3) {
    //         setEmergency(true);
    //       }
    //     })
    //   )
    //   .catch((errors) => {
    //     // react on errors.
    //   });
    axios.get("${url}/core_data/device/installation").then((response) => {
      // console.log( "installation data", response.data);
      const parsedNoOfInstallation = parseInt(response.data.sites);
      setNoOfInstalledDevices(parsedNoOfInstallation);
    });
  }, [duration]);
  var date1 = new Date(ts.slice(0, 19) + " UTC");

  var ws = date1.toString();
  var ds = ws.slice(0, 24);
  var ds = ws.slice(0, 24);

  return (
    <div>
      <div className="contain mt-4">
        <div className="row">
          <div className="col">
            <div
              class="card shadow p-3 mb-2 bg-white rounded text-center"
              style={{ width: "10rem" }}
            >
              <div class="card-header">Total</div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">178</li>
              </ul>
            </div>
          </div>
          <div className="col">
            <div
              class="card shadow p-3 mb-2 bg-white rounded text-center"
              style={{ width: "10rem" }}
            >
              <div class="card-header text-success">Installed</div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item"> 7 </li>
              </ul>
            </div>
          </div>
          <div className="col">
            <div
              class="shadow p-3 mb-2 bg-white rounded text-center card"
              style={{ width: "10rem" }}
            >
              <div class="card-header text-danger ">In Progress</div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item"> 171 </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row mt-2 mb-1">
          <Box sx={{ maxWidth: 150, textAlign: "center" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={duration}
                label="Age"
                onChange={handleChange}
              >
                <MenuItem value={10}>Today</MenuItem>
                <MenuItem value={20}>Last 7 days</MenuItem>
                <MenuItem value={30}>All</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        <div
          className="row  text-center"
          style={{ width: "auto", overflow: "scroll" }}
        >
          <div className="col shadow-md text-center mb-2 ms-2">
            <Example data={dataOfNumberOfCalls} height={250} width={500} />
          </div>
        </div>
        {/* <div className="row mb-4 text-center">
        
          <div className="col-lg-4 text-center">
          <MultipleSelectCheckmarks />
          </div>
          <div className="col-lg-4 text-center">
          <Road/>
          </div>
          <div className="col-lg-4 text-center">
          <Towards />
          </div>
        </div> */}

        {/* <div className="con">
          <div className="row">
            <div className="shadow p-3 mb-5 bg-white rounded text-center col-lg-6 ">
              <ul class="list-group">
                <li
                  style={{ paddingBottom: "0px" }}
                  class="list-group-item text-success"
                >
                  <b>{ds}</b>
                </li>
                <li class="list-group-item text-success">
                  <b>Device last Active time</b>
                </li>
              </ul>
            </div>
            <div className="shadow-md p-1 mb-5 bg-white rounded text-center col-lg-6">
              <ul class="list-group">
                <li class="list-group-item text-primary">
                  <b>Call Duration(sec) :- {callTime}</b>
                </li>
                <li class="list-group-item text-primary">
                  <b>Recent Call Mode :- {callMode}</b>
                </li>
              </ul>
            </div>
          </div>
         
        </div> */}

        {/* <div className="color">
          <div class="square shadow-sm p-3 mb-5  rounded"></div>
          <p className="hi">Device Active and button pressed</p>
          <div class="square shadow-sm p-3 mb-5  rounded"></div>
          <div class="square shadow-sm p-3 mb-5  rounded"></div>
        </div> */}
        {/* <div className="color shadow-sm">
          <div style={{ marginTop: "-20px" }} className="text">
            <h6>Device Color indication</h6>
          </div>
          
          <div style={{ marginLeft: "22px" }} className="wrap1">
            <div class="green ani shadow-none p-3 mb-1 tooltip1">
              <span class="wid-1 tooltiptext1">
                Device Active & Button Pressed
              </span>
            </div>
            <div class="ani shadow-none p-3 mb-1 yellow tooltip1">
              <span class="wid-2 tooltiptext1">Device Active</span>
            </div>
            <div class="ani shadow-none p-3 mb-1 red tooltip1">
              <span class="wid-3 tooltiptext1">Device inactive</span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
export default CardSection;
