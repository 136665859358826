import React from 'react';
import { Helmet } from "react-helmet";
import Users from '../../Components/UserLogs/Users';
import PrimarySearchAppBar from '../../Components/Layout/Header';
import Footer from '../../Components/Home/Footer'

function index() {
  return (
    <React.Fragment>
    <Helmet titleTemplate="PRSS | %s" title="Logs" />
<PrimarySearchAppBar/>
    <Users />
  
  </React.Fragment>
  );
}

export default index;
