import React from 'react';
import { Helmet } from "react-helmet";
import Admin from '../../Components/Admin/Admin';
import PrimarySearchAppBar from '../../Components/Layout/Header';
import Footer from '../../Components/Home/Footer'
import CreateTabs from '../../Components/Admin/CreateTabs';
import NewTable from '../../Components/Admin/NewTable';

function index() {
  return (
    <React.Fragment>
    <Helmet titleTemplate="PRSS | %s" title="Admin" />
<PrimarySearchAppBar/>
    <Admin />
    {/* <NewTable /> */}
   
    
  </React.Fragment>
  );
}

export default index;
