import React, { useEffect, useState, useContext } from "react";
import AppContext from "../../../context/app-context";
import ListDividers from "./List";
import { useParams } from "react-router-dom";
import axios from "axios";
import DeviceMap from "./DeviceMap";
import Video from "../../Components/Recording/Recording"
const url = process.env.REACT_APP_URL

function DeviceDetailsCard() {
  const { siteCode } = useParams();
  const {
    addressData,
    setAddressData,
    sitess,
    setSitess,
    coordinates,
    setCoordinates,
  } = useContext(AppContext);
  const [busStop, setBusStop] = useState("");
  const [road, setRoad] = useState("");
  const [towards, setTowards] = useState("");
  const [installationDate, setInstallationDate] = useState("");
  const [lastDataTime, setLastDataTime] = useState("");
  const [lastCallTime, setLastCalltime] = useState("")
  const [lastCallMode, setLastCallMode] = useState("")
  const [lastCallDuration, setLastCallDuration] = useState("")

  const [healthStatus, setHealthStatus] = useState("")


  const [siteInfo, setSiteInfo] = useState([]);
  useEffect(() => {
    axios.get(`${url}/api/v1/get/master`)
        .then((response) => {
        const j = response.data.data.filter((v) => {
          return v.site_code === siteCode;
        });
        setBusStop(j[0].bus_stop);
        setRoad(j[0].road);
        setTowards(j[0].towards);
        setCoordinates(j[0].gps_coordinates);
        setSitess(j[0].site_code);

        setHealthStatus(j[0].functional)

      });
    axios
      .get(`${url}/core_data/all/devices`)
      .then((response) => {
        const j = response.data.data.filter((v) => {
          return v.site_code === siteCode;
        });
        setInstallationDate(j[0].ts.slice(0, 11));
      });
    axios
      .get(
        `${url}/api/v1/device/status/check?site=${siteCode}&startOffset=2022-08-29&endOffset=2022-09-03`
      )
      .then((response) => {
        if (response.data.data === "no") {
          // the array is defined and has at least one element
          setLastDataTime("Awaiting data");
        } else {
          
          const j = response.data.data.filter((v) => {
            return v.site_code === siteCode;
          });
          setLastDataTime(j[0].ts);
        }
      });

      axios.get(`${url}/api/v1/call/logs?startOffset=2022-08-04&endOffset=2022-09-03&sites=${siteCode}`).then((response)=>{


        console.log(response.data.data);
        // setLastCalltime(response.data.data[0].ts.slice(0,16))
        // setLastCallMode(response.data.data[0].call_mode)
        // setLastCallDuration(response.data.data[0].call_time)
      })
  }, []);
 
  function getDataAlways(time){
    var date1 = new Date(time.slice(0, 19) + " UTC");
          var ws = date1.toString();
          var ds = ws.slice(0, 24);
          return ds

  }

  return (
    <>
      <div className="device">
        <div className="row">
          <div className="col">
            <ListDividers
              t1="Device Name:-"
              v1="ERSS-0001"
              t2="Site Code:-"
              v2={siteCode}
              t3="Bus Stop:-"
              v3={busStop}
              t4="Road:-"
              v4={road}
              t5="Towards:-"
              v5={towards}
              t6="Coordinates"
              v6={coordinates}
              t7="Installation Date"
              v7={installationDate}
            />
          </div>
          <div className="col" style={{     borderRadius: '4px',
    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)'}}>
            <DeviceMap />
            {/* <Video/> */}
          </div>
          <div className="col">
            <ListDividers
              t1="Current Status:-"
              // v1={healthStatus ==="yes" ? "Healthy": "Unhealthy"}
              v1 = "Healthy"
              t2="Last Data:-"

              // v2={getDataAlways(lastDataTime)}
              v2 = "Tue Sep 16 2024 04:41:48"

              t3="Data Interval:-"
              v3="One Hour"
              t4="Call Time:-"
              // v4={getDataAlways(lastCallTime)}
              v4 = "Tue Sep 16 2024 02:43:50"
              t5="Last Call Mode:-"
              // v5={lastCallMode}
              v5 = "Incoming"
              t6="Last Call Duration:-"
              v6="1 Min"
              t7="Last Call Duration"
              // v7={lastCallDuration}
              v7 = "1 Min"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DeviceDetailsCard;
