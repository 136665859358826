import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../../context/app-context";
import axios from "axios";

const url = process.env.REACT_APP_URL

function TopSection() {
  const { message, callTime, setcallTime, ts, setTs, emergency, addressData, numberOfEmergency, setNumberOfEmergency,numberOfFunctional, setNumberOfFunctional, numberOfNonFunctional, setNumberOfNonFunctional } =
    useContext(AppContext);
  
  const [mapData, setMapData] = useState([])
  const [NumberOfDevice, setNumberOfDevice] = useState("")
  const [ distressPercent, setDistressPercent] = useState(0)
  const [ functionalPercent, setFunctionalPercent] = useState(0)
  const [ nonFunctionalPercent, setNonFunctionalPercent] = useState(0)

useEffect(()=>{

  axios.get(`${url}/api/v1/get/master`).then((response) => {

    setMapData(response.data.data)
    setNumberOfDevice(response.data.data.length)
    const d = response.data.data.filter((item)=>{
      return item.emergency === "yes"
    })
    setNumberOfEmergency(d.length)
    const e = response.data.data.filter((item)=>{
      return item.functional === "yes"
    })
    setNumberOfFunctional(e.length)
    const f = response.data.data.filter((item)=>{
      return item.non_functional === "yes"
    })
    setNumberOfNonFunctional(f.length)
  })
  console.log("address data", addressData );
  const d = addressData.filter((item)=>{
    return item.emergency === "no"
  })
  console.log(d);
  setDistressPercent((Math.floor(numberOfEmergency/NumberOfDevice * 100)|| 0))
  setFunctionalPercent((Math.floor(numberOfFunctional/NumberOfDevice * 100)|| 0))
  setNonFunctionalPercent(Math.floor(numberOfNonFunctional/NumberOfDevice * 100)|| 0)

},[])

  return (
    <div>
      <div className="row">
        <div className="col-lg-4">
          <div class="card">
            <div class=" shadow-md card-body card-1">
              <p className="fw-bolder d-inline-block ">In Distress/Emergency</p>
              <img
                className="logo-image float-end mt-4 d-inline-block"
                src="/images/alert.png"
                alt=""
              />
              <p>{numberOfEmergency}</p>
              <p> {Math.floor(parseInt(numberOfEmergency) / parseInt(NumberOfDevice)  * 100) || 0} %</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div class="card">
            <div class="shadow-md card-body card-3">
              <p className="fw-bolder d-inline-block">Operational Sites </p>
              <img
                className="logo-image float-end mt-4 d-inline-block"
                src="/images/rpa.png"
                alt=""
              />
              <p>{numberOfFunctional}</p>
              <p> {(Math.floor( parseInt(numberOfFunctional) / parseInt(NumberOfDevice)  * 100)|| 0)} %</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div class="card">
            <div class="shadow-md card-body card-2">
              <p className="fw-bolder d-inline-block">Non-Operational Sites</p>
              <img
                className="logo-image float-end mt-4 d-inline-block"
                src="/images/disabled.png"
                alt=""
              />
              <p>{numberOfNonFunctional}</p>
              <p> {Math.floor(parseInt(numberOfNonFunctional) / parseInt(NumberOfDevice)  * 100)|| 0} %</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopSection;
