import * as React from "react";
import { useContext } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import AppContext from "../../../context/app-context";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

export default function Road() {
  const {
    setData,
    data,
    addresses,
    road,
    setRoad,
    busStop,
    setBusStop,
    setWc,
  } = useContext(AppContext);

  const names = addresses;
  console.log(road);
  const newArray = names.filter((single) => {
    return (
      single.bus_stop_name === busStop[0] ||
      single.bus_stop_name === busStop[1] ||
      single.bus_stop_name === busStop[2] ||
      single.bus_stop_name === busStop[3]
    );
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRoad(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 120 }}>
        <InputLabel id="demo-multiple-checkbox-label">Road</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={road}
          onChange={handleChange}
          input={<OutlinedInput label="Bus Stop" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {newArray.map((name) => (
            <MenuItem key={name.id} value={name.road_name}>
              <Checkbox checked={road.indexOf(name.road_name) > -1} />
              <ListItemText primary={name.road_name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
