import React from "react";
import { Helmet } from "react-helmet";

import PrimarySearchAppBar from "../../Components/Home/PrimarySearchAppBar";

import NewTable from "../../Components/Admin/NewTable";
import LogsTable from "../../Components/Admin/LogsTable";
import Footer from "../../Components/Home/Footer";


function index(props) {
  return (
    <React.Fragment>
    <Helmet titleTemplate="PRSS | %s" title="Reports" />
<PrimarySearchAppBar/>
    <LogsTable />
    <NewTable/>
  </React.Fragment>
  );
}

export default index;
