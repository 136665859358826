import React, {useContext} from 'react'
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@material-ui/core";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import UploadAndDisplayImage from "./UploadAndDisplayImage";
import Up from "./Up";
import S3 from "react-aws-s3";
import ImageTypeSelect from './ImageTypeSelect';
import AppContext from "../../../context/app-context";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

const url = process.env.REACT_APP_URL

function UploadImageModal(props) {
    const { age } = useContext(AppContext);
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInput = useRef([]);
  const ref = React.createRef();
  const ref1 = React.createRef();
  const [postReplacementImage, setPostReplacementImage] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const replaceDevice = () => {
    const URL = `${url}/api/upload_image`;
    const data = {
      site_code: props.siteCode,
      image_type:age,
      image_url:postReplacementImage
       
    };
    axios
      .post(URL, data)
      .then((response) => {
       
        console.log(response);
alert(response.data.message)
        
      })
      .then(() => {
        window.location.reload()
        console.log("Done");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const file = fileInput.current.files[0];
    const newFileName = fileInput.current.files[0].name;
    console.log(typeof newFileName);
    const nowhite = newFileName.replace(/ /g,'')
    console.log("no white", nowhite);
    console.log("new file name", newFileName);
    const config = {
      bucketName: "coredata-content",
      dirName: "uploads" /* optional */,
      region: "ap-southeast-1",
      accessKeyId: "AKIA5WK6NVEA76RXRMQ3",
      secretAccessKey: "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",
      ContentType: "image/jpeg",
    };
    const ReactS3Client = new S3(config);

    ReactS3Client.uploadFile(file, nowhite).then((data) => {
      console.log(data);
      console.log(data.location);

      if (data.status === 204) {
        console.log("berhasil diupload");
        setPostReplacementImage(data.location)
        alert("Image was successfully uploaded")
      } else {
        console.log("gagal");
      }
    });
  };



  
  return (
    <div>
<Button sx={{"textTransform":"none"}} onClick={handleOpen}>Replace</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="header text-center">
            <div class="text-center mb-3 ">
              <h5 className="d-inline-block">Image Upload</h5>
            </div>
          </div>
          <br />
          <ImageTypeSelect age= {age} />
          <br />
          <div className="row">
              <div className="col">
                <form onSubmit={handleSubmit}>
                  <label>
                    Post-Replacement Image:- <br />
                  </label>
                  <input type="file" ref={fileInput} 
                  
                  onChange={(event) => {
                    console.log(event.target.files[0]);
                    setSelectedImage(event.target.files[0]);
                    
                 
                  }}
                  />
                  <br />
                  <button type="submit"> Upload </button>
                </form>
              </div>

              {selectedImage && (
          <div>
          <img alt="not fount" width={"250px"} src={URL.createObjectURL(selectedImage)} />
          <br />
          <button onClick={()=>setSelectedImage(null)}>Remove</button>
          </div>
        )}


              {/* <div className="col">
                <form onSubmit={handleSubmitAnother}>
                  <label>
                    Pre-Replacement Image:- <br />
                  </label>
                  <input type="file" ref={fileInput} />
                  <br />
                  <button type="submit"> Upload </button>
                </form>
              </div> */}
            </div>
            <div className="bu mt-4 text-center">
              <Button
                onClick={replaceDevice}
                color="warning"
                variant="contained"
              >
                Update Image
              </Button>
            </div>
        </Box>
      </Modal>
    </div>
  )
}

export default UploadImageModal