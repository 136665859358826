import React, { useState, useContext, useEffect } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
// import * as parkData from "./addressData/skateboard-parks.json";
// import mapStyles from "./mapStyles";
import axios from "axios";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import IndividualDevice from "../Device/IndividualDevice";
import AppContext from "../../../context/app-context";
const url = process.env.REACT_APP_URL;

function Map() {
  const { addressData, setAddressData } = useContext(AppContext);
  const [selectedPark, setSelectedPark] = useState(null);

  const [coordinates, setCoordinates] = useState("");
  const [lat, setLat] = useState(null);

  useEffect(() => {
    axios.get(`${url}/api/v1/get/master`).then((response) => {
      setAddressData(response.data.data);
      const x = response.data.data;

      const d = x.map((v) => {
        var f = v.gps_coordinates.split(",");
        var lats = f[0];
        // setLat(lats)
        var long = f[1];
        // console.log("lat", lats);
      });
      const c = "28.622188, 77.219165";
      // console.log(c.slice(0, 9));
      // console.log(c.slice(11, 20));
      setCoordinates(response.data.data.gps_coordinates);
    });
    const listener = (e) => {
      if (e.key === "Escape") {
        setSelectedPark(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

  function createTable(v) {
    var f = v.gps_coordinates.split(",");
    var lats = parseFloat(f[0]);
    const colorr = v.color;
    console.log("colorssss", colorr);

    var long = parseFloat(f[1].slice(1, 10));

    // console.log("a", lats);
    // console.log("b", long);
    return (
      <Marker
        key={v.id}
        position={{
          lat: lats,
          lng: long,
        }}
        onClick={() => {
          setSelectedPark(v);
        }}
        icon={{
          url: v.color,
          scaledSize: new window.google.maps.Size(25, 25),
        }}
      />
    );
  }

  // function info(t){

  //   var f = t.gps_coordinates.split(',')
  //   var lats = parseFloat(f[0])
  //   // setLat(lats)
  //  var long = parseFloat(f[1].slice(1,10))
  //   return ( <InfoWindow
  //     onCloseClick={() => {
  //       setSelectedPark(null);
  //     }}
  //     position={{
  //       lat: lats,
  //       lng: long
  //     }}
  //   >
  //     <div>
  //       <h2>{t.bus_stop_name}</h2>
  //       <p>{t.road_name}</p>
  //       <p>{t.towards}</p>
  //     </div>
  //   </InfoWindow>)
  // }
  function getDataAlways(time) {
    var date1 = new Date(time.slice(0, 19) + " UTC");
    var ws = date1.toString();
    var ds = ws.slice(0, 24);
    return ds;
  }

  return (
    <>
      <GoogleMap
        defaultZoom={13}
        center={{ lat: 28.610075, lng: 77.199127 }}
        //   defaultOptions={{ styles: mapStyles }}
      >
        {addressData.map(createTable)}

        {selectedPark && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedPark(null);
            }}
            position={{
              lat: parseFloat(selectedPark.gps_coordinates.split(",")[0]),
              lng: parseFloat(
                selectedPark.gps_coordinates.split(",")[1].slice(1, 10)
              ),
            }}
          >
            <div>
              <h5>Bus Stop : {selectedPark.bus_stop}</h5>
              <p>Site Code : {selectedPark.site_code}</p>
              <p>Road : {selectedPark.road}</p>

              <p>Towards : {selectedPark.towards}</p>
              <p>
                Last Health Data :{" "}
                {getDataAlways(selectedPark.last_health_data_time)}
              </p>
              <p>
                Last Call Data : {getDataAlways(selectedPark.last_call_time)}
              </p>
              <p>
                Status :{" "}
                {selectedPark.functional === "yes" ? "Healthy" : "Unhealthy"}
              </p>
              <a href={`/device/${selectedPark.site_code}`}>More Details</a>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </>
  );
}

const MapWrapped = withScriptjs(withGoogleMap(Map));

export default function ReactMap() {
  return (
    <div style={{ height: "70vh" }}>
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
}
