import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Box, Container, Grid, Typography } from "@mui/material";
import { AccountProfile } from "../../Components/Account/AccountProfile";
import { AccountProfileDetails } from "../../Components/Account/AccountProfileDetails";
import PrimarySearchAppBar from "../../Components/Layout/Header";
import { useAuth } from "../../../context/AuthContext";
import { getAuth } from "firebase/auth";

function Account() {
  const auth = getAuth();
  const user = auth.currentUser;
  return (
    <>
      <Helmet titleTemplate="PRSS | %s" title="Profile" />
      <PrimarySearchAppBar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h4">
            Account
          </Typography>
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <AccountProfile user={user.providerData[0]} />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <AccountProfileDetails />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Account;
