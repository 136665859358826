import React from 'react'

function Footer() {
    return (
        <div>
           
        <div className="footer text-center mt-4 mb-4">
            <span> &copy; CoreData Networks 2024 | All Rights Reserved. &nbsp; &nbsp; <b>FAQ</b>&nbsp;&nbsp; <b>Repot an issue</b></span>
        </div>
      
        </div>
    )
}

export default Footer
