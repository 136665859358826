

import React, { Component } from 'react'
import Select from 'react-select'



function SelectOption(props) {
  return (
    <Select selected defaultValue={props.opt} options={props.options} />
  )
}

export default SelectOption

