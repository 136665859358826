import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@material-ui/core";
import { useState, useEffect } from "react";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const url = process.env.REACT_APP_URL;

export default function FirmwareModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [newVersion, setNewVersion] = useState("");
  const [previousVersion, setPreviousVersion] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [getRemarks, setGetRemarks] = useState("");

  const deleteDevice = () => {
    const URL = `${url}/update_firmware`;
    const data = {
      imei: props.imei,
      user: props.personInstalled,
      site_code: props.siteCode,
      new_version: newVersion,
      previous_version: previousVersion,
      file_url: fileUrl,
      remarks: getRemarks,
    };
    console.log(data);
    axios
      .post(URL, data)
      .then((response) => {
        console.log(response);
        console.log(response.status);

        alert(response.data.message);
      })
      .then(() => {
        console.log("updated");
      });
  };

  return (
    <div>
      <Button sx={{ textTransform: "none" }} onClick={handleOpen}>
        Update
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="header text-center">
            <div class="text-center mb-3 three">
              <h1 className="d-inline-block">Firmware update</h1>
            </div>
          </div>
          <div className="form mt-4">
            <div className="row">
              <div className="col-lg-6">
                <TextField
                  required
                  id="outlined-required"
                  label="New Version"
                  defaultValue=""
                  onChange={(e) => setNewVersion(e.target.value)}
                />
              </div>
              <div className="col-lg-6">
                <TextField
                  required
                  id="outlined-required"
                  label="Previous Version"
                  defaultValue=""
                  onChange={(e) => setPreviousVersion(e.target.value)}
                />
              </div>
              <div className="col-lg-6">
                <TextField
                  required
                  id="outlined-required"
                  label="File URL"
                  defaultValue=""
                  onChange={(e) => setFileUrl(e.target.value)}
                />
              </div>
              <div className="col-lg-6">
                <TextField
                  required
                  id="outlined-required"
                  label="Remarks"
                  defaultValue=""
                  onChange={(e) => setGetRemarks(e.target.value)}
                />
              </div>
            </div>
            <div className="bu mt-4 text-center">
              <Button
                onClick={deleteDevice}
                color="warning"
                variant="contained"
              >
                Submit
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
