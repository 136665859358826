import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@material-ui/core";
import { useState, useEffect } from "react";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const url = process.env.REACT_APP_URL

export default function DeleteModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [personDeleting, setPersonDeleting] = useState("");
  const [personApproved, setPersonApproved] = useState("");
  const [reason, setReason] = useState("");

  const deleteDevice = () => {
    const URL = `${url}/device/delete`;
    const data = {
      site_code: props.siteCode,
      person_deleted: personDeleting,
      person_approved: personApproved,
      reason: reason
    };
   axios.post(URL, data).then((response)=>{
       console.log(response.message);
       console.log(response.status);
       
           alert(response.data.message)
       
   }).then(()=>{
    window.location.reload()
   })

  };

  return (
    <div>
      <Button sx={{"textTransform":"none"}} onClick={handleOpen}>Delete</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="header text-center">
            <div class="text-center mb-3 three">
              <h1 className="d-inline-block">Device Replacement</h1>
            </div>
          </div>
          <div className="form mt-4">
            <div className="row">
              <div className="col-lg-4">
                <TextField
                  required
                  id="outlined-required"
                  label="Reason"
                  defaultValue=""
                  onChange={(e) => setReason(e.target.value)}
                />
              </div>
              <div className="col-lg-4">
                <TextField
                  required
                  id="outlined-required"
                  label="Person Deleting"
                  defaultValue=""
                  onChange={(e) => setPersonDeleting(e.target.value)}
                />
              </div>
              <div className="col-lg-4">
                <TextField
                  required
                  id="outlined-required"
                  label="Person Approved"
                  defaultValue=""
                  onChange={(e) => setPersonApproved(e.target.value)}
                />
              </div>
            </div>
            {/* <div className="row">
            <div className="col-lg-3">

            </div>
            <div className="col-lg-3">
              
            </div>
            <div className="col-lg-3">
              
            </div>
            <div className="col-lg-3">
              
            </div>
          </div> */}
            <div className="bu mt-4 text-center">
              <Button
                onClick={deleteDevice}
                color="warning"
                variant="contained"
              >
                Delete
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
