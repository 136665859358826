import React, { useState, useContext, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@mui/material/CssBaseline";
import axios from "axios";
import { FormControlLabel, IconButton } from "@material-ui/core";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import Box from "@mui/material/Box";
import AppContext from "../../../context/app-context";
import EditIcon from "@material-ui/icons/Edit";
import { blue } from "@material-ui/core/colors";
import { useDemoData } from "@mui/x-data-grid-generator";
import { darken, lighten } from "@mui/material/styles";
import DeleteModal from "../Admin/DeleteModal";
import Button from "@mui/material/Button";

const url = process.env.REACT_APP_URL;

const MatEdit = ({ index }) => {
  const handleEditClick = () => {
    // some action
  };

  return (
    <FormControlLabel
      control={
        <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={handleEditClick}
        >
          <EditIcon style={{ color: blue[500] }} />
        </IconButton>
      }
    />
  );
};

function getDataAlways(time) {
  var date1 = new Date(time.slice(0, 19) + " UTC");
  var ws = date1.toString();
  var ds = ws.slice(0, 24);
  return ds;
}

const columns = [
  {
    field: "S.No",
    headerName: "S.No.",
    filterable: false,
    width: 60,
    renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
  },

  { field: "bus_stop", headerName: "Bus Stop", width: 160 },
  { field: "road", headerName: "Road", width: 140 },
  {
    field: "towards",
    headerName: "Towards",
    width: 140,
  },
  {
    field: "site_code",
    headerName: "Site Code",
    width: 100,
  },

  {
    field: "ts",
    headerName: "Last Active Time",
    width: 147,
  },
  {
    field: "last_call_time",
    headerName: "Last Call Time",
    width: 147,
  },
  {
    field: "functional",
    headerName: "Active",
    width: 120,
  },
  {
    field: "remarks",
    headerName: "View Details",
    width: 110,
    editable: true,
    renderCell: (params) => {
      const linkto = () => {
        window.location.href = `/device/${params.row.site_code}`;
      };

      return (
        <Button onClick={linkto} variant="contained">
          View
        </Button>
      );
    },
  },
];

// columns.push({id: 3,
//     busStop: "Lodhi Colon",
//     road: "Xt road",
//     towards: "West",
//     siteCode: "205",
//     imei: "85623076591064",
//     simNumber: "8991000903343332812f",
//     lastActiveTime: "2/3/2022, 9:48:35 PM",
//     status: "Operational",})

//     console.log(columns);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    backgroundColor: "black",
  },
  content: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));
const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function DataTable() {
  const { message, callTime, setcallTime, ts, setTs, emergency } =
    useContext(AppContext);
  const [deviceData, setDeviceData] = useState([]);
  React.useEffect(() => {
    axios.get(`${url}/api/v1/get/master`).then((response) => {
      console.log(response.data.data);
      setDeviceData(response.data.data);
    });
  }, []);
  var date1 = new Date(ts.slice(0, 19));
  var ws = date1.toString();
  var ds = ws.slice(0, 24);
  // const [imei1, setImei1] = useState("");
  // const [iccid1, setIccid1] = useState("8991000903343332810f");
  // const [ts, settimestamp1] = useState("");
  // const [heartbeat, setheartbeat] = useState(0);

  // useEffect(() => {
  //   axios
  //     .get(
  //       "https://cors-everywhere.herokuapp.com/http://3.110.221.53:2201/api/v1/get_health_data"
  //     )
  //     .then((response) => {
  //       setIccid1(response.data.data.sim_no);
  //       setheartbeat(response.data.data.switch_state);
  //       setImei1(response.data.data.access_token);
  //       settimestamp1(response.data.data.ts);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  var date1 = new Date(ts.slice(0, 19));
  var ws = date1.toString();
  var ds = ws.slice(0, 24);
  const rows = [
    {
      id: 1,
      busStop: "Palika Kendra",
      road: "Sansad Marg",
      towards: "Parliament House",
      siteCode: "C-22-P",
      imei: "866557054011988",
      simNumber: "8991000903343332810f1",
      lastActiveTime: [ds],
      status: "Operational",
    },
  ];
  const classes = useStyles();
  return (
    <>
      <div style={{ height: 400, width: "auto", overflow: "scroll" }}>
        <Box
          sx={{
            height: 400,
            width: 1,
            "& .super-app-theme--Open": {
              bgcolor: (theme) =>
                getBackgroundColor(theme.palette.info.main, theme.palette.mode),
              "&:hover": {
                bgcolor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode
                  ),
              },
            },
            "& .super-app-theme--Filled": {
              bgcolor: (theme) =>
                getBackgroundColor(
                  theme.palette.success.main,
                  theme.palette.mode
                ),
              "&:hover": {
                bgcolor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode
                  ),
              },
            },
            "& .super-app-theme--PartiallyFilled": {
              bgcolor: (theme) =>
                getBackgroundColor(
                  theme.palette.warning.main,
                  theme.palette.mode
                ),
              "&:hover": {
                bgcolor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode
                  ),
              },
            },
            "& .super-app-theme--Rejected": {
              bgcolor: (theme) =>
                getBackgroundColor(
                  theme.palette.error.main,
                  theme.palette.mode
                ),
              "&:hover": {
                bgcolor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode
                  ),
              },
            },
          }}
        >
          <DataGrid
            align="center"
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            rows={deviceData}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[15]}
            components={{ Toolbar: GridToolbar }}
            getRowClassName={(params) =>
              params.row.color === "/red.png"
                ? "super-app-theme--Rejected"
                : params.row.color === "/orange.png"
                ? "super-app-theme--PartiallyFilled"
                : "super-app-theme--Filled"
            }
          />
        </Box>
      </div>
    </>
  );
}
