import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@material-ui/core";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import UploadAndDisplayImage from "./UploadAndDisplayImage";
import Up from "./Up";
import S3 from "react-aws-s3";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const url = process.env.REACT_APP_URL

export default function ReplacementModal(props) {
  const fileInput = useRef([]);
  const ref = React.createRef();
  const ref1 = React.createRef();
  const [postReplacementImage, setPostReplacementImage] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [personReplaced, setPersonReplaced] = useState("");
  const [personApproved, setPersonApproved] = useState("");
  const [replacementReason, setReplacementReason] = useState("");
  const [newImei, setNewImei] = useState("");
  const [simNumber, setSimNumber] = useState("");
  const [otherRemarks, setOtherRemarks] = useState("");

  const replaceDevice = () => {
    const URL = `${url}/core_data/device/replacement`;
    const data = {
      site_code: props.siteCode,
      new_imei: newImei,
      sim_number: simNumber,
      replacement_reason: replacementReason,
      other_remarks: otherRemarks,
      person_replaced: personReplaced,
      person_approved: personApproved,
      gps_coordinates: "28.601231, 77.222196",
      pre_replacement_image:
        "https://coredata-content.s3.ap-southeast-1.amazonaws.com/uploads/3d0ebff4-a7c5-4793-9610-f51c526cf2b4.png",
      post_replacement_image:postReplacementImage
    };
    axios
      .post(URL, data)
      .then((response) => {
        console.log(response.message);
        console.log(response.status);

        alert(response.data.message);
      })
      .then(() => {
        window.location.reload();
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const file = fileInput.current.files[0];
    const newFileName = fileInput.current.files[0].name;
    console.log(typeof newFileName);
    const nowhite = newFileName.replace(/ /g,'')
    console.log("no white", nowhite);
    console.log("new file name", newFileName);
    const config = {
      bucketName: "coredata-content",
      dirName: "uploads" /* optional */,
      region: "ap-southeast-1",
      accessKeyId: "AKIA5WK6NVEA76RXRMQ3",
      secretAccessKey: "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",
      ContentType: "image/jpeg",
    };
    const ReactS3Client = new S3(config);

    ReactS3Client.uploadFile(file, nowhite).then((data) => {
      console.log(data);
      console.log(data.location);

      if (data.status === 204) {
        console.log("berhasil diupload");
        setPostReplacementImage(data.location)
      } else {
        console.log("gagal");
      }
    });
  };

  const handleSubmitAnother = (e) => {
    e.preventDefault();
    const file = fileInput[1].current.files[0];
    const newFileName = fileInput[1].current.files[0].name;
    console.log("new file name", newFileName);
    const config = {
      bucketName: "coredata-content",
      dirName: "uploads" /* optional */,
      region: "ap-southeast-1",
      accessKeyId: "AKIA5WK6NVEA76RXRMQ3",
      secretAccessKey: "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",
      ContentType: "image/jpeg",
    };
    const ReactS3Client = new S3(config);

    ReactS3Client.uploadFile(file, newFileName).then((data) => {
      console.log(data);
      console.log(data.location);

      if (data.status === 204) {
        console.log("berhasil diupload");
        
      } else {
        console.log("gagal");
      }
    });
  };

  return (
    <div>
      <Button sx={{"textTransform":"none"}} onClick={handleOpen}>Replace</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="header text-center">
            <div class="text-center mb-3 ">
              <h5 className="d-inline-block">Device Replacement</h5>
            </div>
          </div>
          <div className="form mt-4">
            <div className="row">
              <div className="col-lg-4">
                <TextField
                  required
                  id="outlined-required"
                  label="IMEI"
                  defaultValue=""
                  onChange={(e) => setNewImei(e.target.value)}
                />
              </div>
              <div className="col-lg-4">
                <TextField
                  required
                  id="outlined-required"
                  label="Person Replaced"
                  defaultValue=""
                  onChange={(e) => setPersonReplaced(e.target.value)}
                />
              </div>
              <div className="col-lg-4">
                <TextField
                  required
                  id="outlined-required"
                  label="Person Approved"
                  defaultValue=""
                  onChange={(e) => setPersonApproved(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <TextField
                  required
                  id="outlined-required"
                  label="Replacement Reason"
                  defaultValue=""
                  onChange={(e) => setReplacementReason(e.target.value)}
                />
              </div>
              <div className="col-lg-4">
                <TextField
                  required
                  id="outlined-required"
                  label="Sim Number"
                  defaultValue=""
                  onChange={(e) => setSimNumber(e.target.value)}
                />
              </div>
              <div className="col-lg-4">
                <TextField
                  required
                  id="outlined-required"
                  label="Other Remarks"
                  defaultValue=""
                  onChange={(e) => setOtherRemarks(e.target.value)}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <form onSubmit={handleSubmit}>
                  <label>
                    Post-Replacement Image:- <br />
                  </label>
                  <input type="file" ref={fileInput} />
                  <br />
                  <button type="submit"> Upload </button>
                </form>
              </div>
              {/* <div className="col">
                <form onSubmit={handleSubmitAnother}>
                  <label>
                    Pre-Replacement Image:- <br />
                  </label>
                  <input type="file" ref={fileInput} />
                  <br />
                  <button type="submit"> Upload </button>
                </form>
              </div> */}
            </div>

            {/* <UploadAndDisplayImage/> */}
            {/* <Up /> */}
            {/* <div className="row">
            <div className="col-lg-3">

            </div>
            <div className="col-lg-3">
              
            </div>
            <div className="col-lg-3">
              
            </div>
            <div className="col-lg-3">
              
            </div>
          </div> */}
            <div className="bu mt-4 text-center">
              <Button
                onClick={replaceDevice}
                color="warning"
                variant="contained"
              >
                Replace Device
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
