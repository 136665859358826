import React from 'react';
import { Helmet } from "react-helmet";
import Users from '../../Components/UserLogs/Users';
import PrimarySearchAppBar from '../../Components/Home/PrimarySearchAppBar';
import Footer from '../../Components/Home/Footer'
import MqttOverWebsocketConnection from '../../Components/Home/MqttOverWebsocketConnection';
import VerticalSlider from '../../Components/Home/VerticalSlider';


function index(props) {
  return (
    <React.Fragment>
    <Helmet titleTemplate="PRSS | %s" title="Logs" />
<PrimarySearchAppBar text = ""/>
 <MqttOverWebsocketConnection/>
 
  
  </React.Fragment>
  );
}

export default index;
