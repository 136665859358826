import React, {useContext} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import AppContext from "../../../context/app-context";

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
    labels: ['In Emergency',  'Non-operational Sites', 'Operational Sites'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3],
        backgroundColor: [
          '#FC4F4F',
          
          '#F1D00A',
          '#8BDB81',
         
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
       
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          
        ],
        borderWidth: 1,
      },
    ],
  };
  
  var options = {
      plugins :{
          title:{
              display : true
          }
      }
  }

  
function Doughnut1() {
  const { message, callTime,numberOfNonFunctional, setcallTime, ts, setTs, emergency, numberOfFunctional,numberOfEmergency } =
    useContext(AppContext);
  const data = {
    labels: ['In Emergency',  'Non-operational Sites', 'Operational Sites'],
    datasets: [
      {
        label: '# of Votes',
        data: [numberOfEmergency , numberOfNonFunctional, numberOfFunctional],
        backgroundColor: [
          '#FC4F4F',
          
          '#F1D00A',
          '#8BDB81',
         
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
       
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          
        ],
        borderWidth: 1,
      },
    ],
  };
  
  return <Doughnut 
  width={100}
  height={100}
  data={data} 
  options= {options} 
  />;
}

export default Doughnut1;
