import React from "react";
import { Helmet } from "react-helmet";
import UserPage from "../../Components/User/UserPage";
import PrimarySearchAppBar from "../../Components/Layout/Header";

function index() {
  return (
    <React.Fragment>
      <Helmet titleTemplate="PRSS | %s" title="User Management" />
      <PrimarySearchAppBar />
      <UserPage />
    </React.Fragment>
  );
}

export default index;
