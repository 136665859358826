import * as React from "react";
import Switch from "@mui/material/Switch";
import axios from "axios";

export default function Switchs() {
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    const setCall = async (states) => {
    await  axios.post(`https://trackcoredata.com/call/whether?status=${states}`
    ).then((res) => {
console.log(res.data.message);
      })
    }
    console.log(checked);
    if (checked) {
      console.log("True");
      setCall("True");
    }
    else {
      console.log("False");
      setCall("False");
    }
  }, [checked]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
}
