import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";
import LogTable from "./LogTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const url = process.env.REACT_APP_URL

export default function Users() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [logs, setLogs] = React.useState([]);

  useEffect(() => {
    axios
      .get(
        `${url}/core_data/user/all/logs`
      )
      .then((response) => {
        setLogs(response.data.data);
        console.log(response.data.data);
      });
  }, []);

  function createLogTable(singleData, index) {
    var date1 = new Date(singleData.timestamp.slice(0, 19));
    var ws = date1.toString();
    var ds = ws.slice(0, 24);
    return (
      <LogTable
        key={singleData.id}
        ind={index + 1}
        user={singleData.username}
        time={ds}
      />
    );
  }

  return (
    <div>
      
      
       
          <div className="table-wrapper cont">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">User Name</th>
                  <th scope="col">Logged In </th>
                </tr>
              </thead>
              <tbody>{logs.map(createLogTable)}</tbody>
              {/* <tr>
      <th scope="row">1</th>
      <td>Satyam Kumar</td>
      <td>5th April 2022 11:30 p.m.</td>
      
    </tr> */}
            </table>
          </div>
     
      
    </div>
  );
}
