import React, { useEffect, useState } from 'react';

const CallDetails = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData(); // Fetch initial data

    // const intervalId = setInterval(fetchData, 1000); // Fetch data every second

    // return () => {
    //   clearInterval(intervalId); // Clean up the interval on component unmount
    // };
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('http://localhost:5000/logs');
      const jsonData = await response;
      console.log("data received", jsonData);
      setData(jsonData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Datetime</th>
          <th>Client ID</th>
          <th>Source</th>
          <th>Destination</th>
          <th>Duration</th>
          <th>Field 2</th>
        </tr>
      </thead>
      <tbody>
        {/* {data.map((item, index) => (
          <tr key={index}>
            <td>{item.datetime}</td>
            <td>{item.client_id}</td>
            <td>{item.src}</td>
            <td>{item.dst}</td>
            <td>{item.duration}</td>
            <td>{item.field2}</td>
          </tr>
        ))} */}
      </tbody>
    </table>
  );
};

export default CallDetails;
